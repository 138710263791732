import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
  isOpen: boolean;
  kickedBy: string;
  toggle: () => void;
};

export default class KickedModal extends React.Component<Props> {
  render() {
    const { isOpen, kickedBy, toggle } = this.props;

    return (
      <Modal centered isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Forced From Editing</ModalHeader>
        <ModalBody>
          <p>
            Another user ({kickedBy}) has taken over editing this component.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
