import './CanvasImage.scss';

import React from 'react';
import { Button, Carousel, CarouselControl, CarouselItem } from 'reactstrap';
import type { Component } from 'src/types/Component';
import type { Image } from 'src/types/Image';
import type { UIState } from 'src/types/UIState';

import FileModal from '../shared/FileModal';
import Footnotes from '../shared/Footnotes';

type Props = {
  active: boolean;
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  handleUploadMedia: (
    component: Component,
    values: unknown,
    editingItemIndex?: number,
  ) => void;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
  uiState: UIState;
  updateComponentUIState: (
    componentId: number,
    field: string | number,
    value: unknown,
  ) => void;
};
type State = {
  images: Image[];
  modalOpen: boolean;
};
export default class CanvasImage extends React.Component<Props, State> {
  state = {
    images: [],
    modalOpen: false,
  };

  animating = false;

  componentDidMount() {
    this.setImages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { componentData, editingComponentData, updateComponentUIState } =
      this.props;
    if (prevProps.editingComponentData !== editingComponentData) {
      this.setState({ modalOpen: false });
      this.setImages();
    }
    if (
      prevState.images.length > 0 &&
      prevState.images.length !== this.state.images.length
    ) {
      updateComponentUIState(
        componentData.id,
        'activeIndex',
        this.state.images.length - 1,
      );
    }
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  handleNext = () => {
    const { componentData, uiState, updateComponentUIState } = this.props;
    const { images } = this.state;
    const activeIndex =
      uiState && uiState.activeIndex ? uiState.activeIndex : 0;
    if (!this.animating && activeIndex !== images.length - 1)
      updateComponentUIState(componentData.id, 'activeIndex', activeIndex + 1);
  };

  handlePrevious = () => {
    const { componentData, uiState, updateComponentUIState } = this.props;
    const activeIndex =
      uiState && uiState.activeIndex ? uiState.activeIndex : 0;
    if (!this.animating && activeIndex !== 0)
      updateComponentUIState(componentData.id, 'activeIndex', activeIndex - 1);
  };

  setImages = () => {
    const { componentData, editingComponentData } = this.props;
    let images = [];
    const componentDataHasImages =
      componentData.data?.omcms?.images?.length > 0;
    const editingComponentDataHasImages =
      editingComponentData?.data?.omcms?.images?.length > 0;
    if (componentDataHasImages)
      images = componentData.data.omcms.images.slice();
    if (editingComponentData && editingComponentData.id === componentData.id) {
      images = editingComponentDataHasImages
        ? editingComponentData.data.omcms.images.slice()
        : [];
    }
    this.setState({ images: images });
  };

  render() {
    const {
      active,
      componentData,
      editingComponentData,
      handleLockComponent,
      handleComponentLockStatus,
      handleUploadMedia,
      onChange,
      submitting,
      uiState,
    } = this.props;
    const { images, modalOpen } = this.state;
    const activeIndex =
      uiState && uiState.activeIndex ? uiState.activeIndex : 0;

    return (
      <div className="overflow-hidden">
        {images.length > 0 ? (
          <Carousel
            activeIndex={activeIndex}
            interval={false}
            next={this.handleNext}
            previous={this.handlePrevious}
          >
            {images.map((image) => (
              <CarouselItem
                onExiting={this.onExiting}
                onExited={this.onExited}
                key={image.id}
              >
                <img
                  src={image.url}
                  alt={image.description}
                  width={image.customWidth}
                />
                {image.description && !image.descriptionHidden && (
                  <div className="image-description">{image.description}</div>
                )}
              </CarouselItem>
            ))}
            {activeIndex !== 0 && (
              <CarouselControl
                direction="prev"
                onClickHandler={this.handlePrevious}
              />
            )}
            {activeIndex !== images.length - 1 && (
              <CarouselControl
                direction="next"
                onClickHandler={this.handleNext}
              />
            )}
          </Carousel>
        ) : (
          <Button
            className="add-image"
            color="secondary"
            outline
            onClick={this.toggleModal}
          >
            Add Image
          </Button>
        )}
        <Footnotes
          active={active}
          currentData={
            editingComponentData && editingComponentData.id === componentData.id
              ? editingComponentData
              : componentData
          }
          editingComponentData={editingComponentData}
          handleComponentLockStatus={handleComponentLockStatus}
          handleLockComponent={handleLockComponent}
          onChange={onChange}
        />
        <FileModal
          componentData={componentData}
          handleSubmit={handleUploadMedia}
          isOpen={modalOpen}
          submitting={submitting}
          toggle={this.toggleModal}
        />
      </div>
    );
  }
}
