import './AddConnectorModal.scss';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type { Connector } from 'src/types/Connector';
import * as Yup from 'yup';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';
import { connectorName } from '../../../regex';

type Props = {
  connectorAdded: Connector;
  handleSubmit: (values: Connector) => void;
  handleClose: () => void;
  isOpen: boolean;
  submitting: boolean;
  toggle: () => void;
};

export default class AddConnectorModal extends React.Component<Props> {
  connectorSchema = Yup.object().shape({
    name: Yup.string()
      .required('Connector name is required')
      .max(100, 'Connector name must be less than 100 characters')
      .matches(connectorName.pattern, {
        message: `Connector name ${connectorName.description}`,
        excludeEmptyString: true,
      }),
  });

  renderError = (message: string) => <Alert color="danger">{message}</Alert>;

  render() {
    const {
      connectorAdded,
      handleClose,
      handleSubmit,
      isOpen,
      submitting,
      toggle,
    } = this.props;

    return (
      <Modal
        centered
        id="add-connector-modal"
        data-testid="addConnectorModal"
        isOpen={isOpen}
        onClosed={handleClose}
        toggle={toggle}
      >
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={this.connectorSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {/* TODO: future versions of Formik (2+) fix the `placeholder` requirement issue */}
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <ModalHeader toggle={toggle}>Add Connector</ModalHeader>
            <ModalBody>
              {!connectorAdded ? (
                !submitting && (
                  <div>
                    <label htmlFor="name">Connector Name</label>
                    <Input id="name" name="name" type="text" tag={Field} />
                    <ErrorMessage name="name" render={this.renderError} />
                  </div>
                )
              ) : (
                <div className="connector-info">
                  <h5 className="margin-x">{connectorAdded.name}</h5>
                  <Alert color="warning">
                    <span>
                      The private key is your connector password. You will not
                      be able to view it again after closing this modal.
                    </span>
                    <br />
                    <strong>Please write it down and store it securely.</strong>
                  </Alert>
                  <div className="key private">
                    <span>Private Key:</span> {connectorAdded.appSecret}
                  </div>
                  <div className="key public">
                    <span>Public Key:</span> {connectorAdded.appId}
                  </div>
                </div>
              )}
            </ModalBody>

            <ModalFooter>
              {!connectorAdded ? (
                <div>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  <ButtonPlus
                    color="primary"
                    type="submit"
                    loading={submitting}
                    style={{ width: '190px' }}
                    data-testid="addConnectorConfirmation"
                  >
                    Add Connector
                  </ButtonPlus>
                </div>
              ) : (
                <Button color="primary" onClick={toggle}>
                  Close
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    );
  }
}
