import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';

export default class UserFields extends React.Component {
  render() {
    return (
      <form id="user-fields">
        <Row>
          <Col xl="4">
            <h6 className="margin-xx">Change Password</h6>
            <label htmlFor="new-password">New Password</label>
            <Input id="new-password" name="new-password" type="password" />
            <label htmlFor="confirm-password">Confirm New Password</label>
            <Input
              id="confirm-password"
              name="confirm-password"
              type="password"
            />
            <Button className="margin-top-x" color="primary">
              Update
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
}
