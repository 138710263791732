import React from 'react';
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

export type RouterProps = {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Params;
  };
};

const withRouter: (Component) => React.FC = (Component) => (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  return <Component {...props} router={{ location, navigate, params }} />;
};
export default withRouter;
