import './Header.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import type { AppDispatch, RootState } from 'src';

import { logout } from '../../redux/actions/Auth';
import { User } from '../../types/User';
import UserMenu from '../UserMenu/UserMenu';

type Props = {
  backLink?: string;
  backLinkText?: string;
  dispatch: AppDispatch;
  title: string;
  user: User;
};

class Header extends React.Component<Props> {
  handleLogout = () => {
    this.props.dispatch(logout());
  };

  render() {
    const { backLink, backLinkText, title, user } = this.props;

    return (
      <div id="app-header" data-testid="appHeader">
        <Container fluid>
          {backLink && backLinkText && (
            <Link className="back-link" to={backLink}>
              <FontAwesomeIcon icon="chevron-left" />
              {backLinkText}
            </Link>
          )}
          <h1>{title}</h1>
          {user && <UserMenu user={user} onLogout={this.handleLogout} />}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    user: store.Auth.user,
  };
};

export default connect(mapStateToProps)(Header);
