import './ComponentTitleInput.scss';

import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { Alert, Input } from 'reactstrap';
import type { Component } from 'src/types/Component';
import * as Yup from 'yup';

import { debounce } from '../../../../helpers';
import { componentTitle } from '../../../../regex';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
};
export default class ComponentTitle extends React.Component<Props> {
  schema = Yup.object().shape({
    title: Yup.string()
      .nullable()
      .max(255, 'Title must be less than 255 characters')
      .matches(componentTitle.pattern, {
        message: `Component title ${componentTitle.description}`,
        excludeEmptyString: true,
      }),
  });

  handleChange = debounce((title) => {
    const { componentData, onChange } = this.props;
    const values = {
      title: title || null,
    };
    onChange(componentData, values);
  }, 100);

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const { componentData } = this.props;

    return (
      <div id="component-title-input">
        <Formik
          enableReinitialize
          initialValues={{
            title: componentData.title || '',
          }}
          onSubmit={() => null}
          validationSchema={this.schema}
          render={() => (
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <label htmlFor="component-title">Title</label>
              <Field
                id="component-title"
                name="title"
                render={({ field, form }) => (
                  <Input
                    {...field}
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldValue('title', value);
                      this.schema
                        .isValid({
                          title: value,
                        })
                        .then((valid) => {
                          if (valid) this.handleChange(value);
                        });
                    }}
                    data-testid="inspectorComponentTitle"
                  />
                )}
              />
              <ErrorMessage name="title" render={this.renderError} />
            </Form>
          )}
        />
      </div>
    );
  }
}
