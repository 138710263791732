import './CanvasMedia.scss';

import React from 'react';
import { Button } from 'reactstrap';
import type { Component } from 'src/types/Component';

import MediaModal from '../shared/MediaModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = { modalOpen: boolean };
export default class CanvasMedia extends React.Component<Props, State> {
  state = {
    modalOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ modalOpen: false });
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  render() {
    const { componentData, editingComponentData, onChange, submitting } =
      this.props;
    const { modalOpen } = this.state;

    let mediaData = componentData.data;
    if (editingComponentData && editingComponentData.id === componentData.id) {
      mediaData = editingComponentData.data;
    }

    let mediaUrl;
    if (mediaData.source === 'youtube') {
      mediaUrl = `${mediaData.url.replace(
        '/watch?v=',
        '/embed/',
      )}?rel=0&amp;showinfo=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=ytplayer&amp`;
    } else if (mediaData.source === 'vimeo') {
      mediaUrl = `https://player.vimeo.com/video/${mediaData.url
        .split('/')
        .pop()}`;
    } else if (mediaData.source === 'soundcloud') {
      mediaUrl = `https://w.soundcloud.com/player/?url=${mediaData.url}&color=%23546880&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`;
    }

    return (
      <div className="overflow-hidden">
        {mediaData.url ? (
          <div>
            {mediaData.source === 'soundcloud' ? (
              <iframe
                width="100%"
                height="300"
                scrolling="no"
                src={mediaUrl}
                frameBorder="0"
                title={mediaData.name}
                data-testid="soundcloudEmbed"
              />
            ) : (
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  src={mediaUrl}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allowFullScreen
                  title={mediaData.name}
                  data-testid={
                    mediaData.source === 'youtube'
                      ? 'youtubeEmbed'
                      : 'vimeoEmbed'
                  }
                />
              </div>
            )}
            {mediaData.description && (
              <div className="description">{mediaData.description}</div>
            )}
          </div>
        ) : (
          <Button color="secondary" outline onClick={this.toggleModal}>
            Add Media
          </Button>
        )}
        <MediaModal
          componentData={componentData}
          editingComponentData={editingComponentData}
          handleSubmit={(componentDataToSubmit, values) => {
            onChange(componentDataToSubmit, { data: values });
          }}
          isOpen={modalOpen}
          submitting={submitting}
          toggle={this.toggleModal}
        />
      </div>
    );
  }
}
