import './InspectorImage.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import type { Component } from 'src/types/Component';
import type { Image } from 'src/types/Image';
import type { UIState } from 'src/types/UIState';

import { cloneObject, midPoint } from '../../../../helpers';
import DeleteConfirmationModal from '../shared/DeleteConfirmationModal';
import FileModal from '../shared/FileModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleUploadMedia: (
    component: Component,
    values: Component,
    index?: number,
  ) => void;
  handleLockComponent: (id: number) => void;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
  uiState: UIState;
  updateComponentUIState: (id: number, type: string, index: number) => void;
};
type State = {
  addModalOpen?: boolean;
  deleteModalOpen?: boolean;
  images?: Image[];
  imageSelected?: number;
};

export default class InspectorImage extends React.Component<Props, State> {
  state: State = {
    addModalOpen: false,
    deleteModalOpen: false,
    images: [],
    imageSelected: null,
  };

  componentDidMount() {
    this.setImages();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setState({ addModalOpen: false });
      this.setImages();
    }
  }

  handleItemClick = (index) => {
    const { componentData, updateComponentUIState } = this.props;
    updateComponentUIState(componentData.id, 'activeIndex', index);
  };

  onDragStart = () => {
    const { componentData, editingComponentData, handleComponentLockStatus } =
      this.props;
    if (editingComponentData) {
      return;
    }

    handleComponentLockStatus(componentData.id);
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const { componentData, onChange } = this.props;
    const { images } = this.state;

    const newImages = images.filter((image) => draggableId !== image.id);
    const nodeIndex = destination.index;
    const startPos = newImages[nodeIndex - 1] && newImages[nodeIndex - 1].pos;
    const endPos = newImages[nodeIndex] && newImages[nodeIndex].pos;

    const image = images.find((imageToFind) => draggableId === imageToFind.id);
    image.pos = midPoint(startPos, endPos);
    newImages.splice(nodeIndex, 0, image);

    const newValues = {
      data: {
        omcms: {
          images: newImages,
        },
      },
    };
    onChange(componentData, newValues);
  };

  deleteImage = (imageId) => {
    const { componentData, onChange } = this.props;
    const { images } = this.state;

    const newImages = images.filter((image) => image.id !== imageId);

    const omcms = componentData?.data?.omcms
      ? cloneObject(componentData.data.omcms)
      : { images: [] };
    omcms.images = newImages;

    const values = {
      data: {
        omcms,
      },
    };

    onChange(componentData, values);
    this.toggleDeleteModal();
  };

  updateImage = (values) => {
    const { componentData, onChange } = this.props;
    const { imageSelected } = this.state;
    const { images } = this.state;
    const imageIndex = images.findIndex((image) => image.id === imageSelected);
    images[imageIndex].name = values.name;
    images[imageIndex].description = values.description;
    images[imageIndex].descriptionHidden = values.descriptionHidden;
    if (values.customWidth) images[imageIndex].customWidth = values.customWidth;
    else delete images[imageIndex].customWidth;

    // Clone the OMCMS object in case the component contains footnotes data that should be retained
    const omcms = componentData?.data?.omcms
      ? cloneObject(componentData.data.omcms)
      : { images: [] };
    omcms.images = images;

    const newValues = {
      data: {
        omcms,
      },
    };
    onChange(componentData, newValues);
  };

  setImages = () => {
    const { componentData, editingComponentData } = this.props;
    let images = [];
    const componentDataHasImages =
      componentData.data?.omcms?.images?.length > 0;
    const editingComponentDataHasImages =
      editingComponentData?.data?.omcms?.images?.length > 0;
    if (componentDataHasImages)
      images = componentData.data.omcms.images.slice();
    if (editingComponentData && editingComponentData.id === componentData.id) {
      images = editingComponentDataHasImages
        ? editingComponentData.data.omcms.images.slice()
        : [];
    }

    images.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });

    this.setState({ images: images });
  };

  toggleAddModal = async (_?, imageId?: number) => {
    const {
      componentData,
      editingComponentData,
      handleLockComponent,
      handleComponentLockStatus,
    } = this.props;

    if ((!this.state.addModalOpen || imageId) && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      addModalOpen: !this.state.addModalOpen,
    };
    if (!this.state.addModalOpen) newState.imageSelected = imageId;
    else newState.imageSelected = null;
    this.setState(newState);
  };

  toggleDeleteModal = async (_?, imageId?: number) => {
    const {
      componentData,
      editingComponentData,
      handleLockComponent,
      handleComponentLockStatus,
    } = this.props;

    if (imageId && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    const newState: State = {
      deleteModalOpen: !this.state.deleteModalOpen,
    };
    if (!this.state.deleteModalOpen) newState.imageSelected = imageId;
    else newState.imageSelected = null;
    this.setState(newState);
  };

  render() {
    const { componentData, handleUploadMedia, submitting, uiState } =
      this.props;
    const { addModalOpen, deleteModalOpen, images, imageSelected } = this.state;
    const activeIndex =
      uiState && uiState.activeIndex ? uiState.activeIndex : 0;

    return (
      <div>
        <button
          className="add-button"
          onClick={() => this.toggleAddModal(null)}
          title="Add Image"
          type="button"
        >
          <FontAwesomeIcon icon="plus" />
        </button>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="inspector-fields"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {images.length > 0 ? (
                  images.map((image, index) => (
                    <Draggable
                      key={image.id}
                      draggableId={image.id}
                      index={index}
                    >
                      {(draggableProvided, item) => (
                        <div
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          ref={draggableProvided.innerRef}
                          className={`selectable-list-item${
                            item.isDragging || imageSelected === image.id
                              ? ' active'
                              : ''
                          } ${activeIndex === index ? 'active' : ''}`}
                          key={image.id}
                          data-testid={`image${image.id}`}
                          onClick={() => this.handleItemClick(index)}
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <FontAwesomeIcon
                            className="component-icon"
                            icon="image"
                          />
                          <span className="label">{image.name}</span>
                          <button
                            className="edit"
                            onClick={(_) => this.toggleAddModal(_, image.id)}
                            data-testid={`editImage${image.id}`}
                            type="button"
                          >
                            <FontAwesomeIcon
                              className="component-icon"
                              icon="pencil-alt"
                            />
                          </button>
                          <button
                            className="delete"
                            onClick={(_) => this.toggleDeleteModal(_, image.id)}
                            data-testid={`deleteImage${image.id}`}
                            type="button"
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <p className="gray-med no-data">There are no images</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <FileModal
          componentData={componentData}
          editing={imageSelected ? true : false}
          handleSubmit={(componentDataToSubmit, values, changedImage) => {
            if (changedImage) {
              const imageIndex = images.findIndex(
                (image) => image.id === imageSelected,
              );
              handleUploadMedia(componentDataToSubmit, values, imageIndex);
            } else if (!imageSelected) {
              handleUploadMedia(componentDataToSubmit, values);
            } else {
              this.updateImage(values);
            }
          }}
          itemId={imageSelected}
          items={images}
          isOpen={addModalOpen}
          submitting={submitting}
          toggle={this.toggleAddModal}
        />
        <DeleteConfirmationModal
          handleDelete={() => this.deleteImage(imageSelected)}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="image"
        />
      </div>
    );
  }
}
