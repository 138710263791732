import { Reducer } from 'redux';

import {
  REGISTER_USER,
  REGISTER_USER_FAIL,
  REGISTER_USER_SUCCESS,
} from '../../redux/actions/SignUp';
import { BaseAction, BaseState } from '../types/Base';

export const initialState: BaseState = {};

const signUpReducer: Reducer<BaseState, BaseAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        error: null,
        submitting: true,
      };
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        submitting: false,
      };
    case REGISTER_USER_FAIL:
      return {
        ...state,
        error: action.error,
        submitting: false,
      };
    default:
      return state;
  }
};

export default signUpReducer;
