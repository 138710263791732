import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { ComponentLocation } from 'src/types/Component';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  deleteLocation: () => void;
  isOpen: boolean;
  submitting: boolean;
  toggle: (_, location?: ComponentLocation) => Promise<void>;
};

export default class DeleteLocationModal extends React.Component<Props> {
  render() {
    const { deleteLocation, isOpen, submitting, toggle } = this.props;

    return (
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggle}
        data-testid="deleteLocationModal"
      >
        <ModalHeader toggle={toggle}>Delete Location</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete this location?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <ButtonPlus
            color="danger"
            loading={submitting}
            onClick={deleteLocation}
            style={{ width: '190px' }}
          >
            Yes, Delete It
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
