import './CanvasCashFlow.scss';

import { ChangeSet, Column, EditingState } from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-bootstrap4';
import React from 'react';
import type { Component, ComponentData } from 'src/types/Component';
import type { Distribution } from 'src/types/Distribution';

import Footnotes from '../shared/Footnotes';
import TableImport from '../shared/TableImport';

const InlineEditingCell = (meta) => {
  const { value, onValueChange, editingEnabled, column } = meta;
  const inputProps = (({ onBlur, onFocus, onKeyDown, autoFocus }) => ({
    onBlur,
    onFocus,
    onKeyDown,
    autoFocus,
  }))(meta);
  return editingEnabled ? (
    <TableInlineCellEditing.Cell {...meta}>
      <input
        {...inputProps}
        value={value || ''}
        className="form-control"
        type="number"
        data-testid={`${column.name}Editing`}
        onChange={(event) => onValueChange(event.target.value)}
      />
    </TableInlineCellEditing.Cell>
  ) : (
    <TableInlineCellEditing.Cell {...meta} />
  );
};

const FocusableCell = (meta) => {
  const { onClick, ...restProps } = meta;
  return <Table.Cell {...restProps} tabIndex={0} onFocus={onClick} />;
};

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  onChange: (component: Component, values: Component) => void;
  active: boolean;
};
type State = ComponentData & {
  columns?: Column[];
  distributions?: Distribution[];
  frequency?: string;
  firstDistributionDate?: string;
  loadCumulative?: boolean;
  distributionCount?: string;
  editingStateColumnExtensions?: {
    columnName: string;
    editingEnabled: boolean;
  }[];
};
export default class CanvasCashFlow extends React.Component<Props, State> {
  state: State = {
    distributions: [],
    columns: [
      { name: 'period', title: 'Period' },
      { name: 'principal', title: 'Principal' },
      { name: 'earnings', title: 'Earnings' },
    ],
    editingStateColumnExtensions: [
      { columnName: 'period', editingEnabled: false },
    ],
  };

  componentDidMount() {
    this.setCashFlow();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setCashFlow();
    }
  }

  getRowId = (row) => row.id;

  setCashFlow = () => {
    const { componentData, editingComponentData } = this.props;
    let newState: State = this.state;

    if (componentData.data) {
      newState = componentData.data;
    }
    if (
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData.data
    ) {
      newState = editingComponentData.data;
    }

    if (
      newState.distributionCount &&
      newState.distributions.length !== parseInt(newState.distributionCount)
    ) {
      newState.distributions = [
        ...Array(parseInt(newState.distributionCount)).keys(),
      ].map((index) => {
        if (index >= newState.distributions.length) {
          return { id: index + 1, period: `Period ${index + 1}` };
        }

        return {
          id: newState.distributions[index].id,
          period: newState.distributions[index].period,
          principal: newState.distributions[index].principal,
          earnings: newState.distributions[index].earnings,
        };
      });
    }
    this.setState({ ...newState });
  };

  commitChanges = ({ changed }: { changed?: ChangeSet }) => {
    const { distributions } = this.state;
    if (changed) {
      const updatedDistributions = distributions.map((distribution) => {
        if (changed[distribution.id]) {
          const changedProps = JSON.parse(
            JSON.stringify(changed[distribution.id]).replace(/:""/gi, ':null'),
          );
          return { ...distribution, ...changedProps };
        }
        return distribution;
      });
      const { componentData, onChange } = this.props;
      const {
        frequency,
        firstDistributionDate,
        loadCumulative,
        distributionCount,
      } = this.state;
      const values = {
        data: {
          frequency,
          firstDistributionDate,
          loadCumulative,
          distributionCount,
          distributions: updatedDistributions,
        },
      };
      onChange(componentData, values);
    }
  };

  handleImport = (tableData) => {
    const { componentData, onChange } = this.props;

    // Parse HTML, build an array of rows and cell text
    const parsedTable = new DOMParser().parseFromString(tableData, 'text/html');
    const tableArray = [...parsedTable.querySelectorAll('tr')].map((row) => {
      const newRow = [];
      const cells = [...row.querySelectorAll('td')];
      cells.forEach((cell, index) => {
        if (index < 2) newRow.push(cell.textContent.replace(/\D/g, ''));
      });
      return newRow;
    });

    // Iterate through row data and create a table row for each with additional required data
    const distributionRows = tableArray.map((row, index) => {
      return {
        id: index + 1,
        period: `Period ${index + 1}`,
        earnings: parseInt(row[1]) || null, // If NaN, set the value to null instead
        principal: parseInt(row[0]) || null,
      };
    });

    const values = {
      ...componentData,
      data: {
        distributionCount: distributionRows.length,
        distributions: distributionRows,
      },
    };

    onChange(componentData, values);
  };

  render() {
    const {
      active,
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent,
      onChange,
    } = this.props;
    const { distributions, columns, editingStateColumnExtensions } = this.state;

    return (
      <div className="overflow-hidden">
        <div
          className={`grid-table-wrap ${active ? 'active' : ''}`}
          data-testid="cashFlowTableWrap"
        >
          {active && (
            <TableImport
              handleImport={this.handleImport}
              type="cashflow"
              componentId={componentData.id}
            />
          )}
          <Grid rows={distributions} columns={columns} getRowId={this.getRowId}>
            <EditingState
              onCommitChanges={this.commitChanges}
              columnExtensions={editingStateColumnExtensions}
            />
            <Table
              messages={{
                noData:
                  'Increase Number of Distributions in the inspector to add distribution rows',
              }}
              cellComponent={FocusableCell}
            />
            <TableHeaderRow />
            <TableInlineCellEditing
              startEditAction="click"
              cellComponent={InlineEditingCell}
            />
          </Grid>
          <Footnotes
            active={active}
            currentData={
              editingComponentData &&
              editingComponentData.id === componentData.id
                ? editingComponentData
                : componentData
            }
            editingComponentData={editingComponentData}
            handleComponentLockStatus={handleComponentLockStatus}
            handleLockComponent={handleLockComponent}
            onChange={onChange}
          />
        </div>
      </div>
    );
  }
}
