import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { Card, Container } from 'reactstrap';
import { push } from 'redux-first-history';

import type { AppDispatch, RootState } from '../../';
import { submitSignUp } from '../../redux/actions/SignUp';
import SignUpForm from './components/SignUpForm';

type Props = {
  dispatch: AppDispatch;
  error: string;
  submitting: boolean;
};

class SignUp extends React.Component<Props> {
  componentDidMount() {
    document.title = 'Sign Up';
  }

  handleSubmit = (values) => {
    const { dispatch } = this.props;
    dispatch(submitSignUp(values)).then(() => {
      dispatch(push('/login'));
    });
  };

  render() {
    const { error, submitting } = this.props;
    const disableRegistration =
      process.env.REACT_APP_HIDE_REGISTRATION_LINK === 'true';
    return (
      <Container
        className="container-narrow flex-vertical-center no-header-padding"
        data-testid="signUp"
      >
        {disableRegistration && <Navigate to="/login" replace={true} />}
        <Card>
          <SignUpForm
            error={error}
            handleSubmit={this.handleSubmit}
            submitting={submitting}
          />
          <p>
            Already have an account? <Link to="/login">Log In</Link>
          </p>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    error: store.SignUp.error,
    submitting: store.SignUp.submitting,
  };
};

export default connect(mapStateToProps)(SignUp);
