import { BrowserHistory } from 'history';
import { reducer as notifications } from 'react-notification-system-redux';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { LOGOUT } from '../../redux/actions/Auth';
import Auth from './Auth';
import Connectors from './Connectors';
import Error from './Error';
import Offering from './Offering';
import Offerings from './Offerings';
import SignUp from './SignUp';

export default function createRootReducer(history: BrowserHistory) {
  const { routerReducer } = createReduxHistoryContext({
    history,
  });

  const reducers = {
    router: routerReducer,
    Auth,
    Connectors,
    Error,
    Offering,
    Offerings,
    SignUp,
    notifications,
  };

  const appReducer = combineReducers(reducers);

  const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
      const { router } = state;
      // eslint-disable-next-line
      state = { router };
    }

    return appReducer(state, action);
  };

  return { rootReducer, appReducer };
}
