import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { AttachmentType } from 'src/types/Component';

type Props = {
  handleDelete: () => void;
  isOpen: boolean;
  toggle: () => void;
  type: AttachmentType;
};

export default class DeleteConfirmationModal extends React.Component<Props> {
  render() {
    const { handleDelete, isOpen, toggle, type } = this.props;

    let itemType = 'Image';
    if (type === 'media') itemType = 'Media';
    if (type === 'document') itemType = 'Document';
    if (type === 'metric') itemType = 'Key Metric';

    let deleteText = 'this ' + itemType.toLowerCase();

    if (type === 'footnotes') {
      itemType = 'Footnotes';
      deleteText = "this component's footnotes";
    }

    return (
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggle}
        data-testid={`${type}DeleteConfirmationModal`}
      >
        <ModalHeader toggle={toggle}>Delete {itemType}</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete {deleteText}?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={handleDelete}
            style={{ width: '190px' }}
          >
            Yes, Delete It
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
