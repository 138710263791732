import './CanvasDocuments.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import React from 'react';
import { Button } from 'reactstrap';
import type { Component } from 'src/types/Component';
import type { Document } from 'src/types/Document';

import FileModal from '../shared/FileModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleUploadMedia: (
    component: Component,
    values: any, // eslint-disable-line
    editingItemIndex?: number,
  ) => void;
  submitting: boolean;
};
type State = {
  documents: Document[];
  modalOpen: boolean;
};
export default class CanvasDocuments extends React.Component<Props, State> {
  state = {
    documents: [],
    modalOpen: false,
  };

  componentDidMount() {
    this.setDocuments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData) {
      this.setState({ modalOpen: false });
      this.setDocuments();
    }
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  setDocuments = () => {
    const { componentData, editingComponentData } = this.props;
    let documents = [];
    const componentDataHasDocuments =
      componentData?.data?.omcms?.documents?.length > 0;
    const editingComponentDataHasDocuments =
      editingComponentData?.data?.omcms?.documents?.length > 0;
    if (componentDataHasDocuments)
      documents = componentData.data.omcms.documents.slice();
    if (editingComponentData?.id === componentData.id) {
      documents = editingComponentDataHasDocuments
        ? editingComponentData.data.omcms.documents.slice()
        : [];
    }
    this.setState({ documents: documents });
  };

  render() {
    const { componentData, handleUploadMedia, submitting } = this.props;
    const { documents, modalOpen } = this.state;

    return (
      <div className="overflow-hidden">
        {documents.length > 0 ? (
          <div id="documents-list">
            {documents.map((document, index) => {
              const fileType = document.originalName.split('.').pop();
              let fileIcon = 'file-pdf';
              if (['doc', 'docx'].indexOf(fileType) > -1)
                fileIcon = 'file-word';
              if (['xls', 'xlsx'].indexOf(fileType) > -1)
                fileIcon = 'file-excel';
              return (
                <div
                  className="doc-item"
                  key={document.id}
                  data-testid={`component${componentData.id}Document${index}`}
                >
                  <FontAwesomeIcon
                    className="file-icon"
                    /* @ts-expect-error we import all icons in App.ts in order to use this as is */
                    icon={fileIcon}
                    data-testid="fileIcon"
                  />
                  <span className="doc-title">{document.name}</span>
                  <div className="right-buttons">
                    {fileType === 'pdf' && (
                      <button
                        className="doc-link"
                        onClick={() => window.open(document.url)}
                        data-testid="documentLinkButton"
                        type="button"
                      >
                        <FontAwesomeIcon icon="external-link-alt" />
                      </button>
                    )}
                    <button
                      className="doc-download"
                      onClick={() => saveAs(document.url, document.name)}
                      data-testid="documentDownloadButton"
                      type="button"
                    >
                      <FontAwesomeIcon icon="download" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Button
            className="add-document"
            color="secondary"
            outline
            onClick={this.toggleModal}
          >
            Add Document
          </Button>
        )}
        <FileModal
          componentData={componentData}
          handleSubmit={handleUploadMedia}
          isOpen={modalOpen}
          submitting={submitting}
          toggle={this.toggleModal}
          type="document"
        />
      </div>
    );
  }
}
