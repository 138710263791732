import './Offering.scss';

import deepmerge from 'deepmerge';
import React from 'react';
import {
  error as errorMsg,
  info,
  warning,
} from 'react-notification-system-redux';
import { connect } from 'react-redux';
import { getFlatDataFromTree, getTreeFromFlatData } from 'react-sortable-tree';
import type { AppDispatch, RootState } from 'src';
import type {
  Component,
  ComponentData,
  ComponentLocation,
  ComponentType,
} from 'src/types/Component';
import type { Document } from 'src/types/Document';
import type { Image } from 'src/types/Image';
import type { MetricData } from 'src/types/MetricData';
import type {
  Offering as OfferingType,
  OfferingData,
} from 'src/types/Offering';
import type { UIState } from 'src/types/UIState';
import type { User } from 'src/types/User';

import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import withRouter, {
  RouterProps,
} from '../../components/withRouter/withRouter';
import { cloneObject, midPoint } from '../../helpers';
import {
  addComponent,
  addOfferingData,
  deleteComponent,
  forceLockComponent,
  getComponent,
  getComponents,
  getOffering,
  getOfferingData,
  lockComponent,
  saveComponent,
  saveOffering,
  saveOfferingData,
  selectComponent,
  selectLockedComponent,
  unlockComponent,
  updateComponentData,
  updateOfferingFields,
  uploadDocument,
  uploadImage,
} from '../../redux/actions/Offering';
import AddComponentModal from './components/AddComponentModal';
import Canvas from './components/Canvas';
import ComponentList from './components/ComponentList';
import DeleteComponentModal from './components/DeleteComponentModal';
import ForceEditModal from './components/ForceEditModal';
import Inspector from './components/Inspector';
import KickedModal from './components/KickedModal';

type Props = RouterProps & {
  components: Component[];
  dispatch: AppDispatch;
  editingComponentData: Component;
  editingOfferingData: OfferingType;
  error: Record<string, unknown>;
  loading: boolean;
  loadingComponent: number;
  offering: OfferingType;
  offeringData: OfferingData[];
  selectedComponentId: number;
  selectedLockedComponentId: number;
  submitting: boolean;
  user: User;
};

type State = {
  addComponentModalOpen: boolean;
  componentSelectedForDeletion: number;
  componentSelectedForDeletionHasChild: boolean;
  componentSelectedForDeletionHasLockedChild: boolean;
  componentUIState: UIState[];
  deleteComponentModalOpen: boolean;
  forceEditModalOpen: boolean;
  kickedBy: number;
  kickedModalOpen: boolean;
  inspectorOpen: boolean;
  newComponentParentId: number;
  newComponentHpos: number;
  disabledComponentTypes: string[];
  expandedComponents: number[];
  selectedLocation: ComponentLocation;
};

class Offering extends React.Component<Props, Partial<State>> {
  state = {
    addComponentModalOpen: false,
    componentSelectedForDeletion: null,
    componentSelectedForDeletionHasChild: false,
    componentSelectedForDeletionHasLockedChild: false,
    componentUIState: [],
    deleteComponentModalOpen: false,
    forceEditModalOpen: false,
    kickedBy: null,
    kickedModalOpen: false,
    inspectorOpen: true,
    newComponentParentId: null,
    newComponentHpos: null,
    disabledComponentTypes: null,
    expandedComponents: [],
    selectedLocation: null,
  };

  checkLockInterval = null;

  componentDidMount() {
    const { dispatch, router } = this.props;
    const id = router.params.id;
    document.title = 'Offering';
    dispatch(getOffering(parseInt(id))).then((offering) => {
      document.title = offering.name;
    });
    dispatch(getComponents(parseInt(id)));
    dispatch(getOfferingData(parseInt(id)));
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { components, error, selectedComponentId, user } = this.props;
    const { addComponentModalOpen } = this.state;

    // Scroll to component when selectedComponentId changes
    if (
      selectedComponentId &&
      (!prevProps.selectedComponentId ||
        prevProps.selectedComponentId !== selectedComponentId ||
        (prevState.addComponentModalOpen && !addComponentModalOpen))
    ) {
      this.scrollToComponent(selectedComponentId);
    }

    // Wait until we have both user and component data
    // If any components are locked by the current user already then unlock them
    // This would occur if the user lost connection with a locked component
    if ((!prevProps.components || !prevProps.user) && components && user) {
      if (components.some((component) => component.lockedBy === user.email))
        this.unlockUserComponents(components);
    }

    // Cancel the lock check if an error occurs
    if (!prevProps.error && error && this.checkLockInterval)
      clearInterval(this.checkLockInterval);
  }

  componentWillUnmount() {
    const { dispatch, offering, user } = this.props;
    clearInterval(this.checkLockInterval);
    // If any components are still locked, in the case that a user started editing but never saved, then unlock them
    dispatch(getComponents(offering?.id)).then((components) => {
      if (
        components &&
        components.some((component) => component.lockedBy === user.email)
      )
        this.unlockUserComponents(components, true);
    });
  }

  getVerticalPos = (addToTop: boolean) => {
    const { components } = this.props;
    const { newComponentParentId } = this.state;

    // for first component it returns pos value without any boundry
    if (components.length === 0) {
      return midPoint();
    }

    const sortedComponents = components.sort((a, b) => {
      if (a.vpos < b.vpos) {
        return -1;
      }
      if (a.vpos > b.vpos) {
        return 1;
      }
      return 0;
    });

    // this flatData will contain sorted components arranged right after their parent component
    const flatData = getFlatDataFromTree({
      treeData: getTreeFromFlatData({
        flatData: sortedComponents,
        getKey: (node: { id: number }) => node.id,
        getParentKey: (node: { parentId: number }) => node.parentId,
        rootKey: null,
      }),
      getNodeKey: (node: { id: number }) => node.id,
      ignoreCollapsed: false,
    }).map(({ node }) => node);

    if (newComponentParentId) {
      const lastSibling = flatData
        .filter(
          (node: { parentId: number }) =>
            node.parentId === newComponentParentId,
        )
        .pop();

      const lastVpos = lastSibling && lastSibling.vpos;

      // If the component needs to be added to the top of a parallel component, then use its lower sibling's pos as the end boundary
      if (addToTop) return midPoint(null, lastVpos);
      // if component have a sibling then it will return pos value using last sibling's pos as start boundary
      else return midPoint(lastVpos);
    }

    const lastRootNode = flatData
      .filter((node: { parentId: number }) => node.parentId == null)
      .pop();

    // returning pos value using last component's pos as start boundry
    const lastVpos = lastRootNode && lastRootNode.vpos;
    return midPoint(lastVpos);
  };

  handleAddComponent = (type: string) => {
    const { components, dispatch, editingComponentData, offering } = this.props;
    const { newComponentParentId, newComponentHpos } = this.state;
    // Check if a component is being added to the left of a parallel section, in which case it should be added above its sibling
    let addToTop = false;
    if (newComponentParentId) {
      const parentComponent = components.find(
        (component) => component.id === newComponentParentId,
      );
      if (parentComponent.type === 'section-parallel' && newComponentHpos !== 1)
        addToTop = true;
    }
    const values: Component = {
      collapse: false,
      data: {},
      hpos: newComponentHpos || 0,
      offeringId: offering.id,
      parentId: newComponentParentId,
      type: type as ComponentType,
      title: null,
      vpos: this.getVerticalPos(addToTop),
    };

    switch (type) {
      case 'map':
        values.data = {
          locations: [],
          zoom: 0,
        };
        break;
      case 'media':
        values.data = {
          name: null,
          url: null,
          source: null,
        };
        break;
      case 'table':
      case 'text':
        values.data = {
          editorData: {
            content: {},
            output: null,
          },
        };
        break;
      case 'section':
      case 'section-parallel':
        values.title = 'New Section';
        break;
      default:
        break;
    }

    const addComponentDispatch = () => {
      dispatch(addComponent(offering.id, values)).then(() => {
        dispatch(getComponents(offering.id)).then(() => {
          this.setState({
            addComponentModalOpen: false,
            newComponentParentId: null,
          });
        });
      });
    };

    if (editingComponentData) {
      this.handleSaveComponent().then(() => {
        addComponentDispatch();
      });
    } else {
      addComponentDispatch();
    }
  };

  handleAddOfferingData = (metricData: MetricData) => {
    const { dispatch, offering } = this.props;
    return dispatch(addOfferingData(offering.id, metricData)).then(
      (response) => {
        dispatch(getOfferingData(offering.id));
        return response;
      },
    );
  };

  handleGetOfferingData = (noLoading?: boolean) => {
    const { dispatch, offering } = this.props;
    dispatch(getOfferingData(offering.id, noLoading || false));
  };

  handleCanvasClick = () => {
    const {
      components,
      dispatch,
      editingComponentData,
      editingOfferingData,
      selectedComponentId,
      selectedLockedComponentId,
    } = this.props;
    if (selectedComponentId || selectedLockedComponentId) {
      setTimeout(() => {
        dispatch(selectComponent(null));
        // Save the component if there's editing data in the store
        if (editingComponentData) this.handleSaveComponent();
        // Otherwise unlock any components that might be locked by the user, for example after component creation if not data changes
        // were made
        else this.unlockUserComponents(components);
      }, 100);
    } else if (editingOfferingData) this.handleSaveOffering();

    // Clear selected/focused location
    if (this.state.selectedLocation) {
      this.setState({ selectedLocation: null });
    }
  };

  handleComponentDataChange = (component: Component, newData: Component) => {
    const { components, dispatch, editingComponentData, selectedComponentId } =
      this.props;

    // Get the selected component's data
    const selectedComponentData = components.find(
      (componentToFind) => componentToFind.id === selectedComponentId,
    );

    // If the selected component already has unsaved data then use that, otherwise default to its initial data
    const currentData =
      editingComponentData && selectedComponentId === editingComponentData.id
        ? editingComponentData
        : // Empty object is a fallback if selectedComponentData doesn't exist.
          // This is a safeguard against an occasional breaking bug with the table component
          // which I haven't been able to consistently replicate and resolve
          selectedComponentData || {};

    // Merge the incoming data with the existing data
    // deepmerge is required in order to merge nested objects
    // For the data object, revert to the spread operator as we don't want to merge complex nested objects
    // such as text editor values
    const newComponentData = deepmerge(currentData, newData, {
      customMerge: (key) => {
        if (key === 'data')
          return (objA, objB) => {
            return { ...objA, ...objB };
          };
        return null;
      },
    });

    // Update editingComponentData with the merged data
    dispatch(updateComponentData(component.id, newComponentData));

    // If editingComponentData is null then this is the first time this has run for this component since being selected,
    // so we need to lock it and start checking for an incoming force lock from another user on an interval
    if (editingComponentData === null) this.handleLockComponent(component.id);
  };

  handleDeleteComponent = async () => {
    const { components, dispatch, offering } = this.props;
    const { componentSelectedForDeletion } = this.state;
    const scrollPosition = window.scrollY;
    const selectedComponent = components.find(
      (component) => component.id === componentSelectedForDeletion,
    );
    if (!selectedComponent.lockedBy)
      await dispatch(lockComponent(offering.id, componentSelectedForDeletion));
    dispatch(deleteComponent(offering.id, componentSelectedForDeletion)).then(
      () => {
        dispatch(selectComponent(null));
        clearInterval(this.checkLockInterval);
        dispatch(getComponents(offering.id)).then(() => {
          this.setState({ deleteComponentModalOpen: false });
          window.scrollTo({
            top: scrollPosition,
          });
        });
      },
    );
  };

  handleForceLockComponent = () => {
    const { dispatch, offering, selectedLockedComponentId } = this.props;
    dispatch(forceLockComponent(offering.id, selectedLockedComponentId)).then(
      () => {
        this.setState({ forceEditModalOpen: false });
        dispatch(selectComponent(selectedLockedComponentId));
        dispatch(getComponents(offering.id));
      },
    );
  };

  handleLockComponent = (id: number) => {
    const { dispatch, offering, user } = this.props;
    dispatch(lockComponent(offering.id, id));
    // Setting this to a 5 second interval, minimizing the chance that user B makes a change and saves it
    // between intervals, resulting in no locked status ever being received by user A. This would occur if user A
    // selected the component but didn't start making changes, while user B selected it, edited it, and saved it.
    this.checkLockInterval = setInterval(() => {
      dispatch(getComponent(offering.id, id))
        .then((component) => {
          if (component.lockedBy && component.lockedBy !== user.email) {
            this.setState({
              kickedModalOpen: true,
              kickedBy: component.lockedBy,
            });
            dispatch(selectComponent(null));
            clearInterval(this.checkLockInterval);
          }
        })
        .catch(() => {
          dispatch(
            errorMsg({
              autoDismiss: 10,
              title: 'The selected component could not be found',
              message:
                'Its parent component was most likely deleted by another user',
            }),
          );
          dispatch(getComponents(offering.id));
          clearInterval(this.checkLockInterval);
        });
    }, 5000);
  };

  handleSaveComponent = async () => {
    const scrollPosition = window.scrollY;

    const { dispatch, editingComponentData, offering } = this.props;
    await dispatch(
      saveComponent(offering.id, editingComponentData.id, editingComponentData),
    ).then(() => {
      clearInterval(this.checkLockInterval);
      return dispatch(getComponents(offering.id)).then(() => {
        window.scrollTo({
          top: scrollPosition,
        });
      });
    });
  };

  handleSaveOffering = () => {
    const { dispatch, editingOfferingData, offering } = this.props;
    dispatch(saveOffering(offering.id, editingOfferingData)).then(() => {
      dispatch(getOffering(offering.id));
      dispatch(getComponents(offering.id));
    });
  };

  handleOfferingDataChange = (values: OfferingType) => {
    this.props.dispatch(updateOfferingFields(values));
  };

  handleSaveOfferingData = (offeringDataId: number, metricData: MetricData) => {
    const { dispatch, offering } = this.props;
    return dispatch(
      saveOfferingData(offering.id, offeringDataId, metricData),
    ).then((response) => {
      dispatch(getOfferingData(offering.id));
      return response;
    });
  };

  handleOrderUpdate = async (
    component: { id: number },
    componentTwo: { id: number },
  ) => {
    const { dispatch, offering } = this.props;

    const getComponentsAndScroll = () => {
      return dispatch(getComponents(offering.id)).then(() => {
        this.scrollToComponent(component.id);
      });
    };

    dispatch(lockComponent(offering.id, component.id)).then(() => {
      dispatch(
        saveComponent(
          offering.id,
          component.id,
          component,
          Boolean(componentTwo),
        ),
      ).then(() => {
        clearInterval(this.checkLockInterval);
        // If reordering components within parallel component, additionally update the hpos of the sibling component
        if (componentTwo) {
          dispatch(lockComponent(offering.id, componentTwo.id)).then(() => {
            dispatch(
              saveComponent(offering.id, componentTwo.id, componentTwo),
            ).then(() => {
              clearInterval(this.checkLockInterval);
              getComponentsAndScroll();
            });
          });
        } else {
          getComponentsAndScroll();
        }
      });
    });
  };

  handleExpandedComponents = (components: Component[]) => {
    const expandedComponents = components
      .filter((node) => node.expanded)
      .map((node) => node.id);
    this.setState({ expandedComponents });
  };

  handleSelectComponent = (id: number, isLocked: boolean) => {
    const { components, dispatch, editingComponentData, offering, user } =
      this.props;
    const originalComponentData = components.find(
      (component) => component.id === id,
    );

    const dispatchSelect = (selectedId: number) => {
      if (isLocked && originalComponentData.lockedBy !== user.email)
        dispatch(selectLockedComponent(selectedId));
      else dispatch(selectComponent(selectedId));
      dispatch(getComponent(offering.id, selectedId)).then((component) => {
        // Component has been updated, get new data
        if (
          new Date(component.updatedAt) >
          new Date(originalComponentData.updatedAt)
        ) {
          dispatch(getComponents(offering.id)).then(() => {
            dispatch(
              info({
                autoDismiss: 10,
                title: 'Component Has New Data',
                message: `That component has been updated by another user, reloaded with new data`,
              }),
            );
          });
        }
        // If the component's lock status in the response is different from what the client was showing when it was selected
        // then we reconcile with the logic below
        if (
          component.lockedBy &&
          component.lockedBy !== user.email &&
          !isLocked
        ) {
          dispatch(
            warning({
              autoDismiss: 10,
              title: 'Component Locked',
              message: `That component is currently being edited by ${component.lockedBy}`,
            }),
          );
          dispatch(selectComponent(null));
          dispatch(getComponents(offering.id));
        } else if (
          component.lockedBy &&
          component.lockedBy === user.email &&
          !isLocked
        ) {
          dispatch(
            unlockComponent(
              originalComponentData.offeringId,
              originalComponentData.id,
            ),
          );
        } else if (!component.lockedBy && isLocked) {
          dispatch(selectComponent(id));
          dispatch(getComponents(offering.id)).then(() => {
            dispatch(
              info({
                autoDismiss: 10,
                title: 'Component No Longer Locked',
                message: `That component is no longer being edited by ${originalComponentData.lockedBy}, you are free to edit it`,
              }),
            );
          });
        }
      });
    };

    if (editingComponentData) {
      this.handleSaveComponent().then(() => {
        dispatchSelect(id);
      });
    } else {
      dispatchSelect(id);
    }
  };

  // Currently used by hero component
  handleImageUpload = async (image: File): Promise<Image> => {
    const { dispatch } = this.props;
    const itemData: Image = {};

    await dispatch(uploadImage(image)).then((response) => {
      itemData.id = response.medium.key;
      itemData.url = response.medium.location;
      itemData.versions = {
        thumb: response.thumb.location,
        small: response.small.location,
        medium: response.medium.location,
        large: response.large.location,
        xlarge: response.xlarge.location,
        original: response.original.location,
      };
    });

    return itemData;
  };

  handleUploadMedia = (
    component: Component,
    values: {
      file: File;
      name: string;
      description: string;
      descriptionHidden: boolean;
      customWidth: number;
    },
    editingItemIndex?: number,
  ) => {
    // Currently used by hero, image, and documents components
    const { dispatch, editingComponentData } = this.props;
    const uploadMediaType =
      component.type === 'documents' ? uploadDocument : uploadImage;

    dispatch(uploadMediaType(values.file)).then((response) => {
      let items = [];

      // Get data based on component type and whether component has editing data or not
      // Keeping in this format for readability, even if it's repetitive
      if (component.type === 'documents') {
        if (component.data?.omcms?.documents?.length > 0)
          items = cloneObject(component.data.omcms.documents);
        if (editingComponentData?.data?.omcms?.documents)
          items = cloneObject(editingComponentData.data.omcms.documents);
      } else {
        if (component.data?.omcms?.images?.length > 0)
          items = cloneObject(component.data.omcms.images);
        if (editingComponentData?.data?.omcms?.images)
          items = cloneObject(editingComponentData.data.omcms.images);
      }

      items.sort((a, b) => {
        if (a.pos < b.pos) {
          return -1;
        }
        if (a.pos > b.pos) {
          return 1;
        }
        return 0;
      });
      const lastItem = items[items.length - 1];

      const itemData: Document & Image = {
        id: response.key,
        url: response.location,
      };

      if (component.type === 'image') {
        itemData.id = response.medium.key;
        itemData.url = response.medium.location;

        itemData.versions = {
          ...(component.type === 'image' && { thumb: response.thumb.location }),
          ...(component.type === 'image' && { small: response.small.location }),
          medium: response.medium.location,
          large: response.large.location,
          xlarge: response.xlarge.location,
          original: response.original.location,
        };
      }

      if (component.type === 'image' || component.type === 'documents')
        itemData.name = values.name;

      if (component.type === 'documents')
        itemData.originalName = response.originalName;

      if (values.description) itemData.description = values.description;
      if (values.descriptionHidden)
        itemData.descriptionHidden = values.descriptionHidden;
      if (values.customWidth) itemData.customWidth = values.customWidth;

      if (editingItemIndex || editingItemIndex === 0) {
        // If an existing item in a non-hero component is being edited, update that item in the array
        itemData.pos = items[editingItemIndex].pos;
        items[editingItemIndex] = itemData;
      } else {
        // If a new item is being added then push to the array
        const lastPos = lastItem && lastItem.pos;
        itemData.pos = midPoint(lastPos);
        items.push(itemData);
      }

      const data: ComponentData = {
        // Clone the OMCMS object in case the component contains footnotes data that should be retained
        omcms: component.data?.omcms ? cloneObject(component.data?.omcms) : {},
      };

      if (component.type === 'documents') data.omcms.documents = items;
      else data.omcms.images = items;

      this.handleComponentDataChange(component, { data });
    });
  };

  unlockUserComponents = (components: Component[], unmounting?: boolean) => {
    const { dispatch, user } = this.props;

    components.forEach((component) => {
      if (component.lockedBy === user.email) {
        dispatch(unlockComponent(component.offeringId, component.id)).then(
          () => {
            if (!unmounting) dispatch(getComponents(component.offeringId));
          },
        );
      }
    });
  };

  handleComponentLockStatus = (id: number) => {
    const { dispatch, offering, user } = this.props;
    return dispatch(getComponent(offering.id, id)).then((component) => {
      if (component.lockedBy && component.lockedBy !== user.email) {
        this.setState({
          kickedModalOpen: true,
          kickedBy: component.lockedBy,
        });
        dispatch(selectComponent(null));
        clearInterval(this.checkLockInterval);
        return true;
      }
      return false;
    });
  };

  handleLocationSelect = (location: ComponentLocation) => {
    this.setState({ selectedLocation: location });
  };

  handleMapUpdate = (component: Component, data: Component) => {
    this.handleComponentDataChange(component, { data: data });
  };

  scrollToComponent = (componentId: number) => {
    const componentElement = document.getElementById(
      `canvas-component-${componentId}`,
    );
    if (componentElement) {
      const scrollPosition =
        componentElement.getBoundingClientRect().top + window.scrollY - 86; // Minus nav height + 30px of space
      window.scrollTo({
        top: scrollPosition,
      });
    }
  };

  toggleAddComponentModal = (
    parentId?: number,
    disabledComponentTypes?: ComponentType[],
    position?: number,
  ) => {
    const newState = {
      addComponentModalOpen: !this.state.addComponentModalOpen,
      newComponentParentId: parentId || null,
      // Position is only used by parallel columns so users can add directly to the left or right column
      newComponentHpos: position || null,
      disabledComponentTypes: disabledComponentTypes || null,
    };
    this.setState(newState);
  };

  toggleDeleteComponentModal = (id: number) => {
    const { components } = this.props;
    const selectedComponent = components.find(
      (component) => component.id === id,
    );
    const newState: Partial<State> = {
      deleteComponentModalOpen: !this.state.deleteComponentModalOpen,
    };
    if (this.state.deleteComponentModalOpen) {
      newState.componentSelectedForDeletion = null;
      newState.componentSelectedForDeletionHasChild = false;
      newState.componentSelectedForDeletionHasLockedChild = false;
    } else {
      newState.componentSelectedForDeletion = id;
      if (
        selectedComponent.type === 'section' ||
        selectedComponent.type === 'section-parallel'
      ) {
        let hasChild = false;
        let hasLockedChild = false;
        hasChild = components.some((component) => component.parentId === id);
        hasLockedChild = components.some(
          (component) => component.parentId === id && component.lockedBy,
        );
        newState.componentSelectedForDeletionHasChild = hasChild;
        newState.componentSelectedForDeletionHasLockedChild = hasLockedChild;
      }
    }
    this.setState(newState);
  };

  toggleForceEditModal = () => {
    this.setState({
      forceEditModalOpen: !this.state.forceEditModalOpen,
    });
  };

  toggleInspector = () => {
    this.setState({
      inspectorOpen: !this.state.inspectorOpen,
    });
  };

  updateComponentUIState = (
    componentId: number,
    field: string | number,
    value: unknown,
  ) => {
    const { componentUIState } = this.state;
    if (!componentUIState.find((component) => component.id === componentId)) {
      componentUIState.push({
        id: componentId,
      });
    }
    const componentIndex = componentUIState.findIndex(
      (component) => component.id === componentId,
    );
    componentUIState[componentIndex][field] = value;
    this.setState({ componentUIState: componentUIState });
  };

  closeKickedModal = () => {
    const { dispatch, offering } = this.props;
    this.setState({
      kickedBy: null,
      kickedModalOpen: false,
    });
    dispatch(getComponents(offering.id));
  };

  render() {
    const {
      addComponentModalOpen,
      componentSelectedForDeletionHasChild,
      componentSelectedForDeletionHasLockedChild,
      componentUIState,
      deleteComponentModalOpen,
      disabledComponentTypes,
      forceEditModalOpen,
      kickedBy,
      kickedModalOpen,
      inspectorOpen,
      expandedComponents,
      selectedLocation,
    } = this.state;
    const {
      components,
      editingComponentData,
      selectedComponentId,
      selectedLockedComponentId,
      loading,
      loadingComponent,
      offering,
      offeringData,
      submitting,
      user,
    } = this.props;

    const selectedComponent = selectedComponentId
      ? components &&
        components.find((component) => component.id === selectedComponentId)
      : null;

    const selectedLockedComponent = selectedLockedComponentId
      ? components &&
        components.find(
          (component) => component.id === selectedLockedComponentId,
        )
      : null;

    return (
      <div data-testid="offering">
        {loading || !offering ? (
          <Loading />
        ) : (
          <div id="offering">
            <Header
              title={offering.name}
              backLink="/offerings/"
              backLinkText="Offerings"
            />
            <AddComponentModal
              addComponent={this.handleAddComponent}
              disabledComponentTypes={disabledComponentTypes}
              isOpen={addComponentModalOpen}
              submitting={submitting}
              toggle={this.toggleAddComponentModal}
            />
            <DeleteComponentModal
              deleteComponent={this.handleDeleteComponent}
              hasChild={componentSelectedForDeletionHasChild}
              hasLockedChild={componentSelectedForDeletionHasLockedChild}
              isOpen={deleteComponentModalOpen}
              submitting={submitting}
              toggle={this.toggleDeleteComponentModal}
            />
            <ForceEditModal
              forceLockComponent={this.handleForceLockComponent}
              isOpen={forceEditModalOpen}
              selectedLockedComponent={selectedLockedComponent}
              submitting={submitting}
              toggle={this.toggleForceEditModal}
            />
            <KickedModal
              isOpen={kickedModalOpen}
              kickedBy={kickedBy}
              toggle={this.closeKickedModal}
            />
            <ComponentList
              addComponentClick={this.toggleAddComponentModal}
              components={components}
              deleteComponentClick={this.toggleDeleteComponentModal}
              selectComponent={this.handleSelectComponent}
              selectedComponentId={selectedComponentId}
              userEmail={user.email}
              updateComponentOrder={this.handleOrderUpdate}
              expandedComponents={expandedComponents}
              handleExpandedComponents={this.handleExpandedComponents}
            />
            <Canvas
              addComponentClick={this.toggleAddComponentModal}
              components={components}
              componentUIState={componentUIState}
              editingComponentData={editingComponentData}
              handleComponentLockStatus={this.handleComponentLockStatus}
              handleLockComponent={this.handleLockComponent}
              inspectorOpen={inspectorOpen}
              loadingComponent={loadingComponent}
              selectedLocation={selectedLocation}
              onChange={this.handleComponentDataChange}
              handleMapUpdate={this.handleMapUpdate}
              offeringData={offeringData}
              handleAddOfferingData={this.handleAddOfferingData}
              handleGetOfferingData={this.handleGetOfferingData}
              handleImageUpload={this.handleImageUpload}
              handleCanvasClick={this.handleCanvasClick}
              handleSaveOfferingData={this.handleSaveOfferingData}
              handleUploadMedia={this.handleUploadMedia}
              selectComponent={this.handleSelectComponent}
              selectedComponentId={selectedComponentId}
              submitting={submitting}
              updateComponentUIState={this.updateComponentUIState}
              userEmail={user.email}
            />
            <Inspector
              componentUIState={componentUIState}
              editingComponentData={editingComponentData}
              forceEditClick={this.toggleForceEditModal}
              handleComponentLockStatus={this.handleComponentLockStatus}
              handleLocationSelect={this.handleLocationSelect}
              handleMapUpdate={this.handleMapUpdate}
              handleOfferingDataChange={this.handleOfferingDataChange}
              handleAddOfferingData={this.handleAddOfferingData}
              handleGetOfferingData={this.handleGetOfferingData}
              handleImageUpload={this.handleImageUpload}
              handleSaveOfferingData={this.handleSaveOfferingData}
              handleUploadMedia={this.handleUploadMedia}
              isOpen={inspectorOpen}
              offering={offering}
              offeringData={offeringData}
              onChange={this.handleComponentDataChange}
              handleLockComponent={this.handleLockComponent}
              selectedComponent={selectedComponent}
              selectedLockedComponent={selectedLockedComponent}
              selectedLocation={selectedLocation}
              submitting={submitting}
              toggle={this.toggleInspector}
              updateComponentUIState={this.updateComponentUIState}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    components: store.Offering.components,
    editingComponentData: store.Offering.editingComponentData,
    editingOfferingData: store.Offering.editingOfferingData,
    error: store.Error.error,
    selectedComponentId: store.Offering.selectedComponentId,
    selectedLockedComponentId: store.Offering.selectedLockedComponentId,
    loading: store.Offering.loading,
    loadingComponent: store.Offering.loadingComponent,
    offering: store.Offering.offering,
    offeringData: store.Offering.offeringData,
    submitting: store.Offering.submitting,
    user: store.Auth.user,
  };
};

const OfferingWithRouter = withRouter(Offering);

export default connect(mapStateToProps)(OfferingWithRouter);
