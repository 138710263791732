import './OfferingList.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { push } from 'redux-first-history';

import type { AppDispatch, RootState } from '../../../';
import Loading from '../../../components/Loading/Loading';
import {
  addOffering,
  archiveOffering,
  cloneOffering,
  getOfferings,
  publishOffering,
  unpublishOffering,
} from '../../../redux/actions/Offerings';
import { Offering } from '../../../types/Offering';
import CreateOfferingModal from './CreateOfferingModal';
import OfferingListItem from './OfferingListItem';
import PublishOfferingModal from './PublishOfferingModal';
import UnpublishOfferingModal from './UnpublishOfferingModal';

type Props = {
  dispatch: AppDispatch;
  loading: boolean;
  offerings: Offering[];
  submitting: boolean;
  error: string;
};
type State = {
  createModalOpen: boolean;
  publishModalOpen: boolean;
  selectedOfferingId: number;
  unpublishModalOpen: boolean;
  selectedOfferingName: string;
};

class OfferingList extends React.Component<Props, Partial<State>> {
  state = {
    createModalOpen: false,
    publishModalOpen: false,
    selectedOfferingId: null,
    unpublishModalOpen: false,
  };

  componentDidMount() {
    this.props.dispatch(getOfferings());
  }

  handleAddOffering = (values: Offering) => {
    const { dispatch } = this.props;
    dispatch(addOffering(values)).then((response) => {
      dispatch(push(`/offering/${response.id}`));
    });
  };

  handleArchiveOffering = (id: number) => {
    this.props.dispatch(archiveOffering(id)).then(() => {
      this.props.dispatch(getOfferings());
    });
  };

  handleCloneOffering = (id: number) => {
    this.props.dispatch(cloneOffering(id)).then(() => {
      this.props.dispatch(getOfferings());
    });
  };

  handlePublishOffering = () => {
    this.props
      .dispatch(publishOffering(this.state.selectedOfferingId))
      .then(() => {
        this.setState({
          publishModalOpen: false,
        });
        this.props.dispatch(getOfferings());
      });
  };

  handleUnpublishOffering = () => {
    this.props
      .dispatch(unpublishOffering(this.state.selectedOfferingId))
      .then(() => {
        this.setState({
          unpublishModalOpen: false,
        });
        this.props.dispatch(getOfferings());
      });
  };

  toggleModal = (type: string, id?: number) => {
    const { createModalOpen, publishModalOpen, unpublishModalOpen } =
      this.state;
    const newState: Partial<State> = {};
    newState.selectedOfferingId =
      !publishModalOpen && !unpublishModalOpen ? id : null;
    newState.selectedOfferingName = null;
    switch (type) {
      case 'create':
        newState.createModalOpen = !createModalOpen;
        break;
      case 'publish':
        newState.publishModalOpen = !publishModalOpen;
        break;
      case 'unpublish':
        newState.unpublishModalOpen = !unpublishModalOpen;
        break;
      default:
        break;
    }
    this.setState(newState);
  };

  render() {
    const { loading, offerings, submitting, error } = this.props;
    let archivedCount = 0;
    const activeOfferings = [];
    if (offerings) {
      offerings.forEach((offering) => {
        if (offering.archived) archivedCount += 1;
        else activeOfferings.push(offering);
      });
    }
    if (activeOfferings.length > 0) {
      activeOfferings.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
    }

    return loading || !offerings ? (
      <Loading />
    ) : (
      <div className="offering-list">
        <CreateOfferingModal
          error={error}
          handleSubmit={this.handleAddOffering}
          isOpen={this.state.createModalOpen}
          submitting={submitting}
          toggle={() => this.toggleModal('create')}
        />
        <PublishOfferingModal
          publishOffering={this.handlePublishOffering}
          isOpen={this.state.publishModalOpen}
          submitting={submitting}
          toggle={() => this.toggleModal('publish')}
        />
        <UnpublishOfferingModal
          unpublishOffering={this.handleUnpublishOffering}
          isOpen={this.state.unpublishModalOpen}
          submitting={submitting}
          toggle={() => this.toggleModal('unpublish')}
        />
        <Button
          outline
          color="primary"
          onClick={() => this.toggleModal('create')}
        >
          New Offering
        </Button>
        <hr />
        {offerings.length === 0 ? (
          <div className="no-offerings">
            <p className="margin-xx">There are currently no offerings.</p>
            <Button
              outline
              color="secondary"
              onClick={() => this.toggleModal('create')}
            >
              Create New Offering
            </Button>
          </div>
        ) : (
          <div className="offerings">
            {activeOfferings.map((offering) => {
              const id = offering.id;
              return (
                <OfferingListItem
                  key={id}
                  offering={offering}
                  archiveOffering={() => this.handleArchiveOffering(id)}
                  cloneOffering={() => this.handleCloneOffering(id)}
                  togglePublish={() => this.toggleModal('publish', id)}
                  toggleUnpublish={() => this.toggleModal('unpublish', id)}
                />
              );
            })}
          </div>
        )}
        {archivedCount > 0 && (
          <Link className="archive-link" to="/archived-offerings">
            {`Archived (${archivedCount})`}
          </Link>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    error: store.Offerings.error,
    loading: store.Offerings.loading,
    offerings: store.Offerings.offerings,
    submitting: store.Offerings.submitting,
  };
};

export default connect(mapStateToProps)(OfferingList);
