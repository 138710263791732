import './InspectorSourcesAndUses.scss';

import React from 'react';
import type { Component } from 'src/types/Component';
import type { Sources } from 'src/types/Sources';
import type { Uses } from 'src/types/Uses';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;

  submitting: boolean;
};
type State = {
  sourcesRows: Sources[];
  usesRows: Uses[];
  sourcesSummary: boolean;
  usesSummary: boolean;
};
export default class InspectorSourcesAndUses extends React.Component<
  Props,
  State
> {
  state: State = {
    sourcesRows: [],
    usesRows: [],
    sourcesSummary: true,
    usesSummary: true,
  };

  componentDidMount() {
    this.setSourcesAndUsesSummary();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setSourcesAndUsesSummary();
    }
  }

  setSourcesAndUsesSummary = () => {
    const { componentData, editingComponentData } = this.props;
    let newState = {};
    if (componentData && componentData.data) {
      newState = componentData.data;
    }
    if (
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData.data
    ) {
      newState = editingComponentData.data;
    }
    this.setState(newState);
  };

  toggleSummary = (e) => {
    const { componentData, onChange } = this.props;
    const { sourcesRows, sourcesSummary, usesRows, usesSummary } = this.state;
    const values = {
      data: {
        sourcesRows: sourcesRows,
        sourcesSummary: sourcesSummary,
        usesRows: usesRows,
        usesSummary: usesSummary,
      },
    };

    if (e.target.id === 'sources') {
      this.setState({ sourcesSummary: e.target.checked });
      values.data.sourcesSummary = e.target.checked;
    } else {
      this.setState({ usesSummary: e.target.checked });
      values.data.usesSummary = e.target.checked;
    }
    onChange(componentData, values);
  };

  render() {
    const { sourcesSummary, usesSummary } = this.state;

    return (
      <div id="inspector-sources-and-uses">
        <div className="inspector-fields">
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="sources"
              checked={sourcesSummary}
              onChange={this.toggleSummary}
            />
            <label htmlFor="sources">Include Sources Summary Row</label>
          </div>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="uses"
              checked={usesSummary}
              onChange={this.toggleSummary}
            />
            <label htmlFor="uses">Include Uses Summary Row</label>
          </div>
        </div>
      </div>
    );
  }
}
