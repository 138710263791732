import './ComponentListItem.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import type { Component } from 'src/types/Component';

export type Props = PropsWithChildren & {
  active: boolean;
  componentData: Component;
  deleteClick: (id: number) => void;
  inView: boolean;
  name: string;
  selectComponent: (id: number, isLocked?: boolean) => void;
  toggleChildrenVisibility?: () => void;
  type?: string;
  userEmail?: string;
};

export default class ComponentListItem extends React.Component<Props> {
  render() {
    const {
      active,
      children,
      componentData,
      deleteClick,
      inView,
      selectComponent,
      userEmail,
      toggleChildrenVisibility,
    } = this.props;

    let label = componentData.title;
    if (!label) {
      label = componentData.type;
      if (componentData.type === 'cash-flow') label = 'cash flow scenario';
      if (componentData.type === 'section') label = componentData.title;
      if (componentData.type === 'section-parallel') label = 'parallel columns';
      if (componentData.type === 'metrics') label = 'key metrics';
      if (componentData.type === 'sources-and-uses') label = 'sources and uses';
    }

    let icon;
    switch (componentData.type) {
      case 'section':
        icon = 'puzzle-piece';
        break;
      case 'section-parallel':
        icon = 'pause';
        break;
      case 'text':
        icon = 'font';
        break;
      case 'image':
        icon = 'file-image';
        break;
      case 'hero':
        icon = 'image';
        break;
      case 'map':
        icon = 'map-marker-alt';
        break;
      case 'media':
        icon = 'play-circle';
        break;
      case 'metrics':
        icon = 'percent';
        break;
      case 'table':
        icon = 'table';
        break;
      case 'documents':
        icon = 'file-pdf';
        break;
      case 'sources-and-uses':
        icon = 'columns';
        break;
      case 'cash-flow':
        icon = 'chart-bar';
        break;
      default:
        icon = 'square';
        break;
    }

    const locked =
      componentData.lockedBy && componentData.lockedBy !== userEmail
        ? true
        : false;

    return (
      <div
        className={`list-item-wrap ${
          componentData.expanded ? '' : 'collapsed'
        } ${inView ? 'in-view' : ''}`}
        onClick={() => {
          if (!active) selectComponent(componentData.id, locked);
        }}
        data-testid={`componentListItemWrap${componentData.id}`}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <div
          className={`component-list-item selectable-list-item ${
            active ? 'active' : ''
          } ${componentData.type === 'section' ? 'section' : ''}  ${
            locked ? 'locked' : ''
          }`}
          data-testid={`componentListItem${componentData.id}`}
        >
          {componentData.type === 'section' && (
            <button
              className="toggle"
              onClick={() => toggleChildrenVisibility()}
              title="Toggle children visibility"
              type="button"
            >
              <FontAwesomeIcon
                icon={componentData.expanded ? 'caret-down' : 'caret-right'}
              />
            </button>
          )}
          <FontAwesomeIcon className="component-icon" icon={icon} />
          <span className="label">{label}</span>
          <button
            className="delete"
            onClick={() => deleteClick(componentData.id)}
            title="Delete component"
            data-testid={`deleteComponent${componentData.id}`}
            type="button"
          >
            ×
          </button>
        </div>
        {children}
      </div>
    );
  }
}
