import './CanvasKeyMetrics.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row, Tooltip } from 'reactstrap';
import type { Component, ComponentMetricData } from 'src/types/Component';
import type { MetricData } from 'src/types/MetricData';

import KeyMetricsModal from '../shared/KeyMetricsModal';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleAddOfferingData: (data: ComponentMetricData) => Promise<void>;
  handleGetOfferingData: (noLoading: true) => void;
  handleSaveOfferingData: (id: number, data: MetricData) => Promise<void>;
  offeringData: MetricData[];
  onChange: (component: Component, values: Component) => void;
  submitting?: boolean;
};
type State = {
  modalOpen: boolean;
  openTooltips: number[];
};
export default class CanvasKeyMetrics extends React.Component<Props, State> {
  state = {
    modalOpen: false,
    openTooltips: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ modalOpen: false });
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  tooltipHover = (key) => {
    const { openTooltips } = this.state;
    let newArray = openTooltips;
    if (openTooltips.includes(key))
      newArray = openTooltips.filter((k) => k !== key);
    else newArray.push(key);
    this.setState({
      openTooltips: newArray,
    });
  };

  render() {
    const {
      componentData,
      editingComponentData,
      handleAddOfferingData,
      handleGetOfferingData,
      handleSaveOfferingData,
      offeringData,
      onChange,
      submitting,
    } = this.props;
    const { modalOpen, openTooltips } = this.state;
    let metrics = [];
    const componentDataHasOfferingData =
      componentData?.data?.omcms?.offeringData?.length > 0;
    const editingComponentDataHasOfferingData =
      editingComponentData?.id === componentData.id &&
      editingComponentData?.data?.omcms?.offeringData;
    if (componentDataHasOfferingData)
      metrics = componentData.data.omcms.offeringData;
    if (editingComponentDataHasOfferingData)
      metrics = editingComponentData.data.omcms.offeringData;

    return (
      <div className="overflow-hidden">
        {metrics.length > 0 ? (
          <Row>
            {metrics.map((metric, index) => {
              return (
                <Col xs="4" key={metric.key} className="metric-column">
                  {metric.icon && (
                    <FontAwesomeIcon
                      icon={metric.icon}
                      data-testid={`metricIcon${index}`}
                    />
                  )}
                  {offeringData?.map(
                    (data) =>
                      data.key === metric.key && (
                        <div key={data.key}>
                          <div
                            className="label"
                            data-testid={`metricLabel${index}`}
                          >
                            {data.label}
                            {metric.helpText && [
                              <FontAwesomeIcon
                                className="label-info-icon"
                                id={`trigger-helptext-${metric.key}`}
                                icon="info-circle"
                                key="icon"
                                data-testid={`metricHelpTextToggle${index}`}
                              />,
                              <Tooltip
                                isOpen={openTooltips.includes(
                                  `trigger-helptext-${metric.key}`,
                                )}
                                placement="top"
                                target={`trigger-helptext-${metric.key}`}
                                trigger="hover"
                                toggle={() =>
                                  this.tooltipHover(
                                    `trigger-helptext-${metric.key}`,
                                  )
                                }
                                key="tooltip"
                              >
                                {metric.helpText}
                              </Tooltip>,
                            ]}
                          </div>
                          <div
                            className="value"
                            data-testid={`metricValue${index}`}
                          >
                            {data.content}
                          </div>
                        </div>
                      ),
                  )}
                </Col>
              );
            })}
          </Row>
        ) : (
          <Button color="secondary" outline onClick={this.toggleModal}>
            Add Metric
          </Button>
        )}
        <KeyMetricsModal
          componentData={componentData}
          editingComponentData={editingComponentData}
          handleAddOfferingData={handleAddOfferingData}
          handleGetOfferingData={handleGetOfferingData}
          handleSaveOfferingData={handleSaveOfferingData}
          isOpen={modalOpen}
          offeringData={offeringData}
          onChange={onChange}
          submitting={submitting}
          toggle={this.toggleModal}
        />
      </div>
    );
  }
}
