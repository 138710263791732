import './CanvasMap.scss';

import React from 'react';
import { Button } from 'reactstrap';
import type { Component, ComponentLocation } from 'src/types/Component';

import { googleMapURL } from '../../../../constants';
import LocationModal from '../shared/LocationModal';
import MapComponent from '../shared/MapComponent';

type Props = {
  selectedComponentId: number;
  selectedLocation: ComponentLocation;
  componentData: Component;
  editingComponentData: Component;
  handleMapUpdate?: (component: Component, values: Component) => void;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = { infoOpen?: boolean; infoName?: string; modalOpen?: boolean };
export default class CanvasMap extends React.Component<Props, State> {
  state = {
    infoOpen: false,
    infoName: undefined,
    modalOpen: false,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ modalOpen: false });
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  handleShowInfo = (locationName) => {
    const { infoName, infoOpen } = this.state;
    const newState = {
      infoOpen: infoName !== locationName || !infoOpen,
      infoName: locationName,
    };
    this.setState(newState);
  };

  handleSetZoom = (zoom) => {
    const { componentData, editingComponentData, onChange } = this.props;
    const currentData =
      editingComponentData && editingComponentData.id === componentData.id
        ? editingComponentData
        : componentData;

    if (currentData && zoom && currentData.data.zoom !== zoom) {
      const values = {
        data: {
          zoom,
        },
      };
      onChange(currentData, values);
    }
  };

  render() {
    const {
      componentData,
      editingComponentData,
      selectedComponentId,
      selectedLocation,
    } = this.props;
    const { modalOpen, infoOpen, infoName } = this.state;

    let locations = [];
    if (componentData.data) locations = componentData.data.locations;

    if (editingComponentData && editingComponentData.id === componentData.id)
      locations = editingComponentData.data.locations;

    const componentIsSelected = selectedComponentId === componentData.id;

    return (
      <div className="overflow-hidden">
        <MapComponent
          componentIsSelected={componentIsSelected}
          selectedLocation={selectedLocation}
          locations={locations}
          infoOpen={infoOpen}
          infoName={infoName}
          showInfo={this.handleShowInfo}
          setZoom={this.handleSetZoom}
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: locations && locations.length > 0 ? '300px' : '0px',
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
        {!locations ||
          (locations.length === 0 && (
            <Button color="secondary" outline onClick={this.toggleModal}>
              Add Location
            </Button>
          ))}
        <LocationModal
          {...this.props}
          isOpen={modalOpen}
          toggle={this.toggleModal}
        />
      </div>
    );
  }
}
