import 'react-datepicker/dist/react-datepicker.css';
import './InspectorCashFlow.scss';

import { Field, Form, Formik } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import type { Component, ComponentData } from 'src/types/Component';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
  submitting: boolean;
};
type State = ComponentData & {
  distributions?: Record<string, unknown>[];
  frequency?: string;
  firstDistributionDate?: string;
  loadCumulative?: boolean;
  distributionCount?: number;
};
export default class InspectorCashFlow extends React.Component<Props, State> {
  state: State = {
    distributions: [],
    frequency: 'Monthly',
    firstDistributionDate: new Date().toString(),
    loadCumulative: false,
    distributionCount: 0,
  };

  componentDidMount() {
    this.setCashFlow();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setCashFlow();
    }
  }

  setCashFlow = () => {
    const { componentData, editingComponentData } = this.props;
    let state: State = {};
    if (componentData && componentData.data) {
      state = componentData.data;
    }
    if (
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData.data
    ) {
      state = editingComponentData.data;
    }
    state.firstDistributionDate = new Date(
      state.firstDistributionDate || new Date(),
    ).toString();
    this.setState(state);
  };

  handleChange = (data) => {
    const { componentData, onChange } = this.props;
    const values = {
      data: {
        ...this.state,
        ...data,
      },
    };

    onChange(componentData, values);
  };

  render() {
    return (
      <div className="inspector-fields">
        <Formik
          enableReinitialize
          onSubmit={this.handleChange}
          initialValues={{ ...this.state }}
          render={({ submitForm }) => (
            <Form
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <label htmlFor="distributionCount">Number of Distributions</label>
              <Field
                name="distributionCount"
                render={({ field, form }) => (
                  <input
                    {...field}
                    min="0"
                    type="number"
                    id="distributionCount"
                    className="form-control"
                    onChange={(e) => {
                      form.setFieldValue('distributionCount', e.target.value);
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                  />
                )}
              />
              <hr />
              <h6>Chart Settings</h6>
              <label htmlFor="frequency">Frequency</label>
              <Field
                name="frequency"
                render={({ field, form }) => (
                  <select
                    {...field}
                    id="frequency"
                    className="form-control"
                    value={field.value}
                    onChange={(e) => {
                      form.setFieldValue('frequency', e.target.value);
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                  >
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Annual">Annual</option>
                  </select>
                )}
              />
              <label htmlFor="firstDistributionDate">
                First Distribution Date
              </label>
              <Field
                name="firstDistributionDate"
                render={({ field, form }) => (
                  <DatePicker
                    {...field}
                    dateFormat="dd/MM/yyyy"
                    id="firstDistributionDate"
                    className="form-control"
                    selected={new Date(field.value)}
                    onChange={(date) => {
                      form.setFieldValue('firstDistributionDate', date);
                      setTimeout(() => {
                        submitForm();
                      });
                    }}
                  />
                )}
              />
              <div className="checkbox-group">
                <Field
                  name="loadCumulative"
                  render={({ field, form }) => (
                    <input
                      {...field}
                      type="checkbox"
                      id="loadCumulative"
                      checked={field.value}
                      onChange={(e) => {
                        form.setFieldValue('loadCumulative', e.target.checked);
                        setTimeout(() => {
                          submitForm();
                        });
                      }}
                    />
                  )}
                />
                <label htmlFor="loadCumulative">Load as Cumulative</label>
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}
