const apiRoot = 'api';
export const productionApp =
  process.env.REACT_APP_STAGE === 'development' ||
  process.env.REACT_APP_STAGE === 'staging' ||
  process.env.REACT_APP_STAGE === 'production';

// Use the local development URL by default for convenience
let resolvedApiUrl = `http://localhost:8001/${apiRoot}`;

// 'staging' is needed for CI so it's safe to hard-code here
if (productionApp) {
  resolvedApiUrl = `/${apiRoot}`;
}

// 'REACT_APP_API_URL' environment variable should have highest precedence.
// Override previous values if it is set
if (process.env.REACT_APP_API_URL) {
  resolvedApiUrl = `${process.env.REACT_APP_API_URL}/${apiRoot}`;
}

// Output resolved API url for troubleshooting purposes
//console.log(`API URL: ${resolvedApiUrl}`);

export const apiUrl = resolvedApiUrl;

let googleMapKey = 'AIzaSyC9oCLOwAJQZLSZL7u3hqOSWuYemKJ4yM8';

if (productionApp && process.env.REACT_APP_GOOGLE_MAP_API_KEY) {
  googleMapKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
}

export const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${googleMapKey}&libraries=geometry,drawing,places`;

let googleAuthClientId =
  '861068468826-uaklqhj2e2i5e8a54lafjd3d5ohnhbhp.apps.googleusercontent.com';

if (productionApp && process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID) {
  googleAuthClientId = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;
}

export const googleClientId = googleAuthClientId;
