import './CanvasTable.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Value } from 'slate';
import DeepTable from 'slate-deep-table';
import { Editor, getEventTransfer } from 'slate-react';
import type { Component } from 'src/types/Component';

import { debounce } from '../../../../helpers';
import {
  initialSlateTableContent,
  slateTableHtmlSerializer,
} from '../helpers/slate';
import Footnotes from '../shared/Footnotes';
import TableImport from '../shared/TableImport';

type Props = {
  active: boolean;
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  onChange: (component: Component, values: Component) => void;
};

export default class CanvasTable extends React.Component<Props> {
  // TODO: current Slate version does not support TS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editor = React.createRef() as any;

  initialValue = Value.fromJSON(
    this.props.componentData.data.editorData.content.document
      ? this.props.componentData.data.editorData.content
      : initialSlateTableContent,
  );

  state = {
    value: this.initialValue,
  };

  plugins = [DeepTable()];

  handleChange = debounce((value) => {
    const { componentData, onChange } = this.props;

    const values = {
      data: {
        editorData: {
          content: value.toJSON(),
          output: slateTableHtmlSerializer.serialize(value),
        },
      },
    };

    onChange(componentData, values);
  }, 100);

  handlePaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    if (transfer.type === 'fragment') {
      const { fragment } = transfer;
      const topNode = fragment.nodes.first();
      if (topNode.type === 'table') {
        // If the root node is a table, find the cell node and insert all children
        const cellNode = topNode.findDescendant(
          (node) => node.type === 'table_cell',
        );
        if (cellNode) {
          cellNode.nodes.forEach((node) => {
            const selectedBlock = editor.value.focusBlock;
            // If the selected block is an empty paragraph then replace it
            if (
              selectedBlock.type === 'paragraph' &&
              selectedBlock.text === ''
            ) {
              editor
                .replaceNodeByKey(selectedBlock.key, node)
                .moveForward(1)
                .moveToEndOfBlock();
            } else {
              editor.insertBlock(node);
            }
          });
          return;
        }
      }
    }

    return next();
  };

  handleSetState = ({ value }) => {
    const hasChanged = value.document !== this.state.value.document;
    this.setState({ value }, () => {
      if (hasChanged) this.handleChange(this.state.value);
    });
  };

  handleInsertColumn = () => {
    this.handleSetState(this.editor.current.insertColumn());
  };

  handleInsertRow = () => {
    this.handleSetState(this.editor.current.insertRow());
  };

  handleRemoveColumn = () => {
    this.handleSetState(this.editor.current.removeColumn());
  };

  handleRemoveRow = () => {
    this.handleSetState(this.editor.current.removeRow());
  };

  handleClickMark = (e, type) => {
    e.preventDefault();
    this.editor.current.toggleMark(type);
  };

  handleImport = (tableData, hasHeader, hasFooter, firstColumnIsHeader) => {
    const { componentData, onChange } = this.props;

    // Set header/footer summary options
    const boldElements = {
      data: {
        firstColumnIsHeader,
        hasHeader,
        hasFooter,
      },
    };
    onChange(componentData, boldElements);

    // Set table data
    const { document } = slateTableHtmlSerializer.deserialize(tableData);
    const newData = {
      document: {
        nodes: document.nodes.toJSON(),
      },
    };
    const value = Value.fromJSON(newData);

    this.handleSetState({ value });
  };

  hasMark = (type) =>
    this.state.value.activeMarks.some((mark) => mark.type === type);

  handleClickLink = (e) => {
    e.preventDefault();

    const editor = this.editor.current;
    const { value } = editor;

    if (this.hasLinks()) {
      editor.command(this.unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      editor.command(this.wrapLink, href);
    } else {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      const text = window.prompt('Enter the text for the link:');

      if (text == null) return;

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(this.wrapLink, href);
    }
  };

  hasLinks = () =>
    this.state.value.inlines.some((inline) => inline.type === 'link');

  wrapLink = (editor, href) => {
    editor.wrapInline({
      type: 'link',
      data: { href },
    });
    editor.moveToEnd();
  };

  unwrapLink = (editor) => {
    editor.unwrapInline('link');
  };

  renderInline = (props, _, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'link':
        const { data } = node;
        const href = data.get('href');
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            {...attributes}
          >
            {children}
          </a>
        );
      default:
        return next();
    }
  };

  renderMark = (props, _, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underline':
        return <u {...attributes}>{children}</u>;
      case 'superscript':
        return <sup {...attributes}>{children}</sup>;
      default:
        return next();
    }
  };

  renderMarkButton = (type, icon) => (
    <button
      className={`toggle-button ${this.hasMark(type) ? 'active' : ''}`}
      onMouseDown={(event) => this.handleClickMark(event, type)}
      title={type}
      type="button"
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

  render() {
    const {
      active,
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent,
      onChange,
    } = this.props;
    const hasHeader =
      editingComponentData?.id === componentData.id && editingComponentData.data
        ? editingComponentData.data.hasHeader
        : componentData.data.hasHeader;
    const hasFooter =
      editingComponentData?.id === componentData.id && editingComponentData.data
        ? editingComponentData.data.hasFooter
        : componentData.data.hasFooter;
    const firstColumnIsHeader =
      editingComponentData?.id === componentData.id && editingComponentData.data
        ? editingComponentData.data.firstColumnIsHeader
        : componentData.data.firstColumnIsHeader;

    return (
      <div className="overflow-hidden">
        {active && (
          <div>
            <TableImport
              handleImport={this.handleImport}
              type="table"
              componentId={componentData.id}
            />
            <div
              className="editor-toolbar table-toolbar"
              data-testid="editorToolbar"
            >
              <button
                className="add"
                onClick={this.handleInsertRow}
                title="add row"
                type="button"
              >
                <FontAwesomeIcon icon="plus" />
                <span>Row</span>
              </button>
              <button
                className="remove"
                onClick={this.handleRemoveRow}
                title="remove row"
                type="button"
              >
                <FontAwesomeIcon icon="minus" />
                <span>Row</span>
              </button>
              <button
                className="add"
                onClick={this.handleInsertColumn}
                title="add column"
                type="button"
              >
                <FontAwesomeIcon icon="plus" />
                <span>Col</span>
              </button>
              <button
                className="remove margin-right"
                onClick={this.handleRemoveColumn}
                title="remove column"
                type="button"
              >
                <FontAwesomeIcon icon="minus" />
                <span>Col</span>
              </button>
              {this.renderMarkButton('bold', 'bold')}
              {this.renderMarkButton('italic', 'italic')}
              {this.renderMarkButton('underline', 'underline')}
              {this.renderMarkButton('superscript', 'superscript')}
              <button
                className={`toggle-button ${this.hasLinks() ? 'active' : ''}`}
                onMouseDown={this.handleClickLink}
                title="hyperlink"
                type="button"
              >
                <FontAwesomeIcon icon="link" />
              </button>
            </div>
          </div>
        )}
        <Editor
          id={`editor-${componentData.id.toString()}`}
          className={`slate-editor ${hasHeader ? ' has-header' : ''} ${
            hasFooter ? ' has-footer' : ''
          } ${firstColumnIsHeader ? ' first-column-is-header' : ''}`}
          plugins={this.plugins}
          value={this.state.value}
          ref={this.editor}
          onChange={({ value }) => this.handleSetState({ value })}
          renderInline={this.renderInline}
          onPaste={this.handlePaste}
          renderMark={this.renderMark}
        />
        <Footnotes
          active={active}
          currentData={
            editingComponentData && editingComponentData.id === componentData.id
              ? editingComponentData
              : componentData
          }
          editingComponentData={editingComponentData}
          handleComponentLockStatus={handleComponentLockStatus}
          handleLockComponent={handleLockComponent}
          onChange={onChange}
        />
      </div>
    );
  }
}
