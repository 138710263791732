import React from 'react';
import { CompactPicker } from 'react-color';
import reactCSS from 'reactcss';

type Props = {
  form: {
    setFieldValue: (name: string, color: string) => void;
  };
  field: {
    name: string;
    value: string;
  };
};
type State = { displayColorPicker?: boolean; color?: string };
export default class ColorPicker extends React.Component<Props, State> {
  state: State = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.rgb });
    const {
      form: { setFieldValue },
      field: { name },
    } = this.props;
    setFieldValue(name, color.hex);
    this.handleClose();
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.props.field.value}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    const { field, ...custom } = this.props;

    return (
      <div>
        <button style={styles.swatch} onClick={this.handleClick} type="button">
          <div style={styles.color} data-testid="colorSwatch" />
        </button>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <button
              style={styles.cover}
              onClick={this.handleClose}
              type="button"
            />
            <CompactPicker
              {...field}
              {...custom}
              color={this.state.color}
              onChange={this.handleChange}
              onClick={this.handleClose}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
