import './GridTable.scss';

import {
  ChangeSet,
  Column,
  EditingState,
  SelectionState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableInlineCellEditing,
  TableSelection,
} from '@devexpress/dx-react-grid-bootstrap4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import type { Sources } from 'src/types/Sources';
import type { Uses } from 'src/types/Uses';

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const getRowId = (row) => row.id;

const InlineEditingCell = (meta) => {
  const {
    value,
    onValueChange,
    editingEnabled,
    column: { name },
  } = meta;
  const inputProps = (({ onBlur, onFocus, onKeyDown, autoFocus }) => ({
    onBlur,
    onFocus,
    onKeyDown,
    autoFocus,
  }))(meta);
  return editingEnabled ? (
    <TableInlineCellEditing.Cell {...meta}>
      <input
        {...inputProps}
        value={value || ''}
        className="form-control"
        type={name === 'amount' ? 'number' : 'text'}
        onChange={(event) => onValueChange(event.target.value)}
      />
    </TableInlineCellEditing.Cell>
  ) : (
    <TableInlineCellEditing.Cell {...meta} />
  );
};

const Cell = (data) => {
  const { row, onClick, ...restProps } = data;
  let { value } = data;
  if (value && restProps.column.name === 'amount') {
    value = currencyFormatter.format(value);
  }
  return (
    <Table.Cell {...restProps} tabIndex={0} onFocus={onClick}>
      <span
        style={{
          fontWeight: row.isBold ? 700 : 'normal',
          fontStyle: row.isItalic ? 'italic' : 'normal',
          textDecoration: row.isUnderline ? 'underline' : 'none',
          float: restProps.column.name === 'amount' ? 'right' : 'none',
        }}
        title={value}
      >
        {value}
      </span>
    </Table.Cell>
  );
};

type SingleRow = Sources | Uses;

type Props = {
  title: string;
  rows: SingleRow[];
  active: boolean;
  summary: boolean;
  onChange: (data: SingleRow[]) => void;
  testId: string;
};
type State = {
  columns?: Column[];
  selection?: number[];
};

export default class GridTable extends React.PureComponent<Props, State> {
  state: State = {
    columns: [{ name: 'name' }, { name: 'amount' }],
  };

  commitChanges = ({ changed }: { changed?: ChangeSet }) => {
    const { rows, onChange } = this.props;
    if (changed) {
      const updatedRows = rows.map((row) => {
        if (changed[row.id]) {
          const changedProps = JSON.parse(
            JSON.stringify(changed[row.id]).replace(/:""/gi, ':null'),
          );
          return { ...row, ...changedProps };
        }
        return row;
      });
      onChange(updatedRows);
    }
  };

  changeSelection = (data) => {
    if (data.length) {
      this.setState({ selection: [data[0].rowId] });
    }
  };

  addNewRow = () => {
    const { rows, onChange } = this.props;
    const lastRow = rows[rows.length - 1];

    const updatedRows = [
      ...rows,
      {
        id: ((lastRow && lastRow.id) || rows.length) + 1,
        isBold: false,
        isItalic: false,
        isUnderline: false,
      },
    ];
    onChange(updatedRows);
  };

  removeSelectedRow = () => {
    const { selection } = this.state;
    const { rows, onChange } = this.props;

    if (!selection) {
      return;
    }

    const updatedRows = rows.filter((row) => selection[0] !== row.id);
    onChange(updatedRows);
  };

  toggleStyle = (prop) => {
    const { selection } = this.state;
    const { rows, onChange } = this.props;

    if (!selection) {
      return;
    }

    const updatedRows = rows.map((row) => {
      if (selection[0] === row.id) {
        row[prop] = !row[prop];
      }
      return row;
    });
    onChange(updatedRows);
  };

  totalValue = () => {
    const { rows } = this.props;
    return rows
      .map((row) => row.amount)
      .filter((amount) => amount)
      .reduce((a, b) => (parseFloat(a) + parseFloat(b)).toString(), '0');
  };

  render() {
    const { columns, selection } = this.state;
    const { active, rows, title, summary, testId } = this.props;

    return (
      <div
        className={`grid-table-wrap ${active ? 'active' : ''}`}
        data-testid={testId || 'gridTable'}
      >
        {active && (
          <div className="editor-toolbar" data-testid="gridTableEditorToolbar">
            <button
              className="add"
              onClick={this.addNewRow}
              title="add row"
              type="button"
            >
              <FontAwesomeIcon icon="plus" /> Row
            </button>
            <button
              className="remove"
              onClick={this.removeSelectedRow}
              title="remove row"
              type="button"
            >
              <FontAwesomeIcon icon="minus" /> Row
            </button>
            <button
              className="bold"
              onClick={() => this.toggleStyle('isBold')}
              title="bold"
              type="button"
            >
              <FontAwesomeIcon icon="bold" />
            </button>
            <button
              className="italic"
              onClick={() => this.toggleStyle('isItalic')}
              title="italic"
              type="button"
            >
              <FontAwesomeIcon icon="italic" />
            </button>
            <button
              className="underline"
              onClick={() => this.toggleStyle('isUnderline')}
              title="underline"
              type="button"
            >
              <FontAwesomeIcon icon="underline" />
            </button>
          </div>
        )}
        <h6>{title}</h6>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <EditingState
            onCommitChanges={this.commitChanges}
            onEditingCellsChange={this.changeSelection}
          />
          <Table cellComponent={Cell} />
          <TableInlineCellEditing
            startEditAction="click"
            cellComponent={InlineEditingCell}
          />
          <SelectionState selection={selection} />
          {active && (
            <TableSelection highlightRow showSelectionColumn={false} />
          )}
        </Grid>
        {summary && (
          <h6 className="summary-row">
            <span className="total-label">Total {title}</span>
            <span className="total-value" data-testid={`${testId}TotalValue`}>
              {currencyFormatter.format(parseInt(this.totalValue()))}
            </span>
          </h6>
        )}
      </div>
    );
  }
}
