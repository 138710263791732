// Slate constants and helper functions
import React from 'react';
import DeepTable from 'slate-deep-table';
import Html from 'slate-html-serializer';

const linkSerializer = {
  serialize(obj, children) {
    if (obj.object === 'inline' && obj.type === 'link') {
      const href = obj.data.get('href');
      return <a href={href}>{children}</a>;
    }

    return undefined;
  },
};

const blockTags = {
  p: 'paragraph',
  li: 'list-item',
  ul: 'bulleted-list',
  ol: 'numbered-list',
  blockquote: 'quote',
  br: 'br',
};

const markTags = {
  strong: 'bold',
  em: 'italic',
  u: 'underline',
  s: 'strikethrough',
  sup: 'superscript',
};

const markSerializerRules = {
  deserialize: (el, next) => {
    let mark = markTags[el.tagName.toLowerCase()];

    // Select a mark for text that doesn't have the correct tag,
    // but has inline styles indicating how it should be formatted
    if (!mark && el.style) {
      // Bold
      if (Number(el.style.fontWeight) > 500) mark = markTags.strong;
      // Italic
      if (el.style.fontStyle === 'italic') mark = markTags.em;
      // Strikethrough
      if (el.style.textDecoration === 'line-through') mark = markTags.s;
      // Superscript
      if (el.style.verticalAlign === 'super') mark = markTags.sup;
      // Underline
      if (el.style.textDecoration === 'underline') mark = markTags.u;
    }

    // The Apple-tab-span class is applied by Google docs around tabs
    // Detect it and replace with the &emsp; character for correct rendering
    // in the HTML output
    if (el.tagName === 'SPAN' && el.className === 'Apple-tab-span') {
      return {
        object: 'inline',
        type: 'tab',
        nodes: next(el.childNodes),
      };
    }

    if (mark) {
      return {
        object: 'mark',
        type: mark,
        nodes: next(el.childNodes),
      };
    }

    return undefined;
  },
  serialize(obj, children) {
    if (obj.object === 'mark') {
      switch (obj.type) {
        case 'bold':
          return <strong>{children}</strong>;
        case 'italic':
          return <em>{children}</em>;
        case 'underline':
          return <u>{children}</u>;
        case 'strikethrough':
          return <s>{children}</s>;
        case 'superscript':
          return <sup>{children}</sup>;
        default:
          return children;
      }
    }
  },
};

const textHtmlSerializerRules = [
  {
    deserialize: (el, next) => {
      const block = blockTags[el.tagName.toLowerCase()];
      const customData: { className?: string } = {};

      if (block) {
        // Ignore line breaks
        if (block === 'br') return null;

        // Remove Ps from list items
        let children = el.childNodes;
        if (el.tagName === 'LI' && el.firstChild.tagName === 'P')
          children = el.firstChild.childNodes;

        // If a paragraph has no margin bottom and is not followed by a line break then add a class that removes bottom margin
        // This is a common scenario when pasting from Google docs
        const zeroMarginBottomValues = ['0pt', '0px', '0em', '0rem'];
        if (
          el.tagName === 'P' &&
          el.nextSibling &&
          el.nextSibling.tagName !== 'BR' &&
          zeroMarginBottomValues.includes(el.style.marginBottom)
        ) {
          customData.className = 'zero-margin-bottom';
        }

        return {
          object: 'block',
          data: customData,
          type: block,
          nodes: next(children),
        };
      }

      return undefined;
    },
    serialize(obj, children) {
      if (obj.object === 'block') {
        const className = obj.data.get('className');
        switch (obj.type) {
          case 'paragraph':
            return <p className={className || null}>{children}</p>;
          case 'quote':
            return <blockquote>{children}</blockquote>;
          case 'bulleted-list':
            return <ul>{children}</ul>;
          case 'numbered-list':
            return <ol>{children}</ol>;
          case 'list-item':
            return <li>{children}</li>;
          case 'br':
            return null;
          default:
            return children;
        }
      }
    },
  },
  markSerializerRules,
  {
    // Special case for links to grab their href
    deserialize(el, next) {
      if (el.tagName.toLowerCase() === 'a') {
        return {
          object: 'inline',
          type: 'link',
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute('href'),
          },
        };
      }

      return undefined;
    },
    serialize(obj, children) {
      if (obj.object === 'inline') {
        switch (obj.type) {
          case 'link':
            const href = obj.data.get('href');
            return (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {children}
              </a>
            );
          case 'tab':
            return <span>&emsp;{children}</span>;
          default:
            return children;
        }
      }
    },
  },
];

export const slateTextHtmlSerializer = new Html({
  rules: textHtmlSerializerRules,
});

const tableHtmlSerializerCustomRules = [markSerializerRules];

export const slateTableHtmlSerializer = new Html({
  rules: [
    linkSerializer,
    ...DeepTable.makeSerializerRules(),
    ...tableHtmlSerializerCustomRules,
  ],
});

export const initialSlateTableContent = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'table',
        data: {
          headless: true,
        },
        nodes: [
          {
            object: 'block',
            type: 'table_row',
            nodes: [
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            object: 'block',
            type: 'table_row',
            nodes: [
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            object: 'block',
            type: 'table_row',
            nodes: [
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            object: 'block',
            type: 'table_row',
            nodes: [
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            object: 'block',
            type: 'table_row',
            nodes: [
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
              {
                object: 'block',
                type: 'table_cell',
                nodes: [
                  {
                    object: 'text',
                    text: '',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};

export const slateTextSchema = {
  inlines: {
    tab: {
      isVoid: true,
    },
  },
};

export const initialSlateTextContent = {
  document: {
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        nodes: [
          {
            object: 'text',
            text: '',
          },
        ],
      },
    ],
  },
};
