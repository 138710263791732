import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import type { Component } from 'src/types/Component';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  isOpen: boolean;
  forceLockComponent: () => void;
  selectedLockedComponent: Component;
  submitting: boolean;
  toggle: () => void;
};

export default class ForceEditModal extends React.Component<Props> {
  render() {
    const {
      forceLockComponent,
      isOpen,
      selectedLockedComponent,
      submitting,
      toggle,
    } = this.props;

    return (
      <Modal
        centered
        isOpen={isOpen}
        toggle={toggle}
        id="force-edit-modal"
        data-testid="forceEditModal"
      >
        <ModalHeader toggle={toggle}>Force Edit</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to force edit this component?</p>
          <p>
            Recent changes made by {selectedLockedComponent?.lockedBy} will not
            be saved.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            No
          </Button>
          <ButtonPlus
            color="secondary"
            onClick={forceLockComponent}
            loading={submitting}
            style={{ width: '150px' }}
            data-testid="submitForceEditModal"
          >
            Yes
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
