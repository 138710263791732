import './Inspector.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'reactstrap';
import type {
  Component,
  ComponentLocation,
  ComponentMetricData,
} from 'src/types/Component';
import type { Image } from 'src/types/Image';
import type { MetricData } from 'src/types/MetricData';
import type { Offering, OfferingData } from 'src/types/Offering';
import type { UIState } from 'src/types/UIState';

import InspectorCashFlow from './inspector/InspectorCashFlow';
import InspectorDocuments from './inspector/InspectorDocuments';
import InspectorHero from './inspector/InspectorHero';
import InspectorImage from './inspector/InspectorImage';
import InspectorKeyMetrics from './inspector/InspectorKeyMetrics';
import InspectorMap from './inspector/InspectorMap';
import InspectorMedia from './inspector/InspectorMedia';
import InspectorOffering from './inspector/InspectorOffering';
import InspectorSourcesAndUses from './inspector/InspectorSourcesAndUses';
import InspectorTable from './inspector/InspectorTable';
import CollapseToggle from './shared/CollapseToggle';
import ComponentTitleInput from './shared/ComponentTitleInput';

type Props = {
  componentUIState: UIState[];
  editingComponentData: Component;
  forceEditClick: (id?: number) => void;
  handleOfferingDataChange: (offering: Offering) => void;
  handleAddOfferingData: (data: ComponentMetricData) => Promise<void>;
  handleGetOfferingData: (noLoading: true) => void;
  handleSaveOfferingData: (id: number, data: MetricData) => Promise<void>;
  handleImageUpload: (image: File) => Promise<Image>;
  handleUploadMedia: (
    component: Component,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any,
    editingItemIndex?: number,
  ) => void;
  isOpen: boolean;
  offering: Offering;
  offeringData: OfferingData[];
  onChange: (component: Component, values: Component) => void;
  selectedLocation: Record<string, unknown>;
  handleMapUpdate: (component: Component, data: Component) => void;
  handleLocationSelect: (location: ComponentLocation) => void;
  selectedComponent: Component;
  handleLockComponent: (id: number) => void;
  selectedLockedComponent: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  submitting: boolean;
  toggle: () => void;
  updateComponentUIState?: (
    componentId: number,
    field: string | number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
  ) => void;
};

export default class Inspector extends React.Component<Props> {
  handleForceEditClick = () => {
    this.props.forceEditClick();
  };

  renderFields = () => {
    const {
      componentUIState,
      editingComponentData,
      handleAddOfferingData,
      handleGetOfferingData,
      handleSaveOfferingData,
      handleImageUpload,
      handleUploadMedia,
      offering,
      offeringData,
      onChange,
      handleOfferingDataChange,
      selectedLocation,
      handleLocationSelect,
      handleMapUpdate,
      selectedComponent,
      handleLockComponent,
      handleComponentLockStatus,
      submitting,
      updateComponentUIState,
    } = this.props;

    const componentProps = {
      componentData: selectedComponent,
      editingComponentData: editingComponentData,
      onChange: onChange,
      submitting: submitting,
    };

    let title = 'Offering Details';
    let fieldsComponent = (
      <InspectorOffering
        offering={offering}
        onChange={handleOfferingDataChange}
      />
    );

    if (selectedComponent) {
      title =
        selectedComponent.type.charAt(0).toUpperCase() +
        selectedComponent.type.slice(1);
      const selectedComponentUIState =
        componentUIState.find(
          (component) => component.id === selectedComponent.id,
        ) || null;

      switch (selectedComponent.type) {
        case 'cash-flow':
          title = 'Cash Flow';
          fieldsComponent = <InspectorCashFlow {...componentProps} />;
          break;
        case 'documents':
          fieldsComponent = (
            <InspectorDocuments
              {...componentProps}
              handleLockComponent={handleLockComponent}
              handleComponentLockStatus={handleComponentLockStatus}
              handleUploadMedia={handleUploadMedia}
            />
          );
          break;
        case 'hero':
          fieldsComponent = (
            <InspectorHero
              {...componentProps}
              handleImageUpload={handleImageUpload}
            />
          );
          break;
        case 'image':
          fieldsComponent = (
            <InspectorImage
              {...componentProps}
              handleLockComponent={handleLockComponent}
              handleComponentLockStatus={handleComponentLockStatus}
              handleUploadMedia={handleUploadMedia}
              updateComponentUIState={updateComponentUIState}
              uiState={selectedComponentUIState}
            />
          );
          break;
        case 'map':
          fieldsComponent = (
            <InspectorMap
              {...componentProps}
              handleLockComponent={handleLockComponent}
              handleComponentLockStatus={handleComponentLockStatus}
              handleMapUpdate={handleMapUpdate}
              handleLocationSelect={handleLocationSelect}
              selectedLocation={selectedLocation}
            />
          );
          break;
        case 'media':
          fieldsComponent = <InspectorMedia {...componentProps} />;
          break;
        case 'metrics':
          fieldsComponent = (
            <InspectorKeyMetrics
              {...componentProps}
              handleComponentLockStatus={handleComponentLockStatus}
              handleLockComponent={handleLockComponent}
              handleAddOfferingData={handleAddOfferingData}
              handleGetOfferingData={handleGetOfferingData}
              handleSaveOfferingData={handleSaveOfferingData}
              offeringData={offeringData}
            />
          );
          break;
        case 'sources-and-uses':
          title = 'Sources and Uses';
          fieldsComponent = <InspectorSourcesAndUses {...componentProps} />;
          break;
        case 'section-parallel':
          title = 'Parallel Columns';
          fieldsComponent = null;
          break;
        case 'table':
          fieldsComponent = <InspectorTable {...componentProps} />;
          break;
        default:
          fieldsComponent = null;
      }
    }

    const showTitleField =
      selectedComponent &&
      !['section', 'hero'].includes(selectedComponent.type);

    const showCollapseField =
      selectedComponent && !['hero'].includes(selectedComponent.type);

    return (
      <div
        id={`inspector-${
          selectedComponent ? selectedComponent.type : 'offering'
        }`}
      >
        <h6 className="sidebar-title">{title}</h6>
        {fieldsComponent}
        {showTitleField && (
          <ComponentTitleInput
            componentData={selectedComponent}
            editingComponentData={editingComponentData}
            onChange={onChange}
          />
        )}
        {showCollapseField && (
          <CollapseToggle
            componentData={selectedComponent}
            editingComponentData={editingComponentData}
            onChange={onChange}
          />
        )}
      </div>
    );
  };

  render() {
    const { isOpen, selectedLockedComponent, toggle, forceEditClick } =
      this.props;

    return (
      <div id="inspector-wrap">
        <div
          id="inspector"
          data-testid="inspector"
          className={`sidebar ${selectedLockedComponent ? 'locked' : ''} ${
            isOpen ? '' : 'closed'
          }`}
        >
          <button
            id="inspector-toggle"
            onClick={toggle}
            title="Toggle inspector visibility"
            type="button"
          >
            {isOpen ? (
              <FontAwesomeIcon icon="angle-right" />
            ) : (
              <FontAwesomeIcon icon="angle-left" />
            )}
          </button>
          <div className="inspector-content">
            {selectedLockedComponent ? (
              <div className="locked-block" data-testid="lockedBlock">
                <div className="text">
                  <FontAwesomeIcon icon="lock" />
                  <p>Component locked by {selectedLockedComponent.lockedBy}</p>
                  <Button
                    color="secondary"
                    title="Force Edit"
                    onClick={() => forceEditClick(selectedLockedComponent.id)}
                  >
                    Force Edit
                  </Button>
                </div>
              </div>
            ) : (
              <div data-testid="inspectorComponentFields">
                {this.renderFields()}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
