import './DeleteConnectorModal.scss';

import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type { Connector } from 'src/types/Connector';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  connector: Connector;
  handleSubmit: (name: string) => void;
  isOpen: boolean;
  submitting: boolean;
  toggle: () => void;
};
type State = { error: string; name: string };
export default class DeleteConnectorModal extends React.Component<
  Props,
  State
> {
  state = {
    error: '',
    name: '',
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value, error: '' });
  };

  onClose = () => {
    this.setState({ name: '', error: '' });
  };

  onSubmit = () => {
    const { connector, handleSubmit } = this.props;
    const { name } = this.state;
    if (name === connector.name) {
      handleSubmit(name);
    } else {
      this.setState({ error: 'The connector name is incorrect' });
    }
  };

  render() {
    const { connector, isOpen, toggle, submitting } = this.props;
    const { error, name } = this.state;

    return (
      <Modal
        centered
        id="delete-connector-modal"
        data-testid="deleteConnectorModal"
        isOpen={isOpen}
        toggle={toggle}
        onClosed={this.onClose}
      >
        <ModalHeader toggle={toggle}>Delete Connector</ModalHeader>
        <ModalBody>
          <div>
            <p>
              This action cannot be reversed. Enter the name of the connector to
              confirm.
            </p>
            <label htmlFor="connector-name">Connector Name</label>
            <Input
              name="connector-name"
              id="connector-name"
              type="text"
              value={name}
              onChange={this.handleNameChange}
            />
            {error && <Alert color="danger">{error}</Alert>}
          </div>
        </ModalBody>

        <ModalFooter>
          <div>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            <ButtonPlus
              color="danger"
              disabled={connector && name !== connector.name ? true : false}
              loading={submitting}
              onClick={this.onSubmit}
              style={{ width: '160px' }}
            >
              Yes, Delete It
            </ButtonPlus>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}
