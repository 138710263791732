import './CanvasSourcesAndUses.scss';

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import type { Component } from 'src/types/Component';

import Footnotes from '../shared/Footnotes';
import GridTable from '../shared/GridTable';
import TableImport from '../shared/TableImport';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  onChange: (component: Component, values: Component) => void;
  active: boolean;
};
type State = {
  sourcesRows?: Record<string, unknown>[];
  usesRows?: Record<string, unknown>[];
  sourcesSummary?: boolean;
  usesSummary?: boolean;
};
export default class CanvasSourcesAndUses extends React.Component<
  Props,
  State
> {
  state = {
    sourcesRows: [],
    usesRows: [],
    sourcesSummary: true,
    usesSummary: true,
  };

  componentDidMount() {
    this.setSourcesAndUses();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.componentData.id !== this.props.componentData.id ||
      prevProps.editingComponentData !== this.props.editingComponentData
    ) {
      this.setSourcesAndUses();
    }
  }

  handleImport = (tableData, table) => {
    // Parse HTML, build an array of rows and cell text
    const parsedTable = new DOMParser().parseFromString(tableData, 'text/html');
    const tableArray = [...parsedTable.querySelectorAll('tr')].map((row) => {
      const newRow = [];
      const cells = [...row.querySelectorAll('td')];
      cells.forEach((cell, index) => {
        if (index < 2) {
          const cellText =
            index === 1
              ? cell.textContent?.replace(/\D/g, '')
              : cell.textContent;
          newRow.push(cellText);
        }
      });
      return newRow;
    });

    // Iterate through row data and create a table row for each with additional required data
    const outputRows = tableArray.map((row, index) => {
      return {
        name: row[0],
        amount: parseInt(row[1]) || null, // If NaN, set the value to null instead
        id: index + 1,
        isBold: false,
        isItalic: false,
        isUnderline: false,
      };
    });

    this.onChange(outputRows, table);
  };

  onChange = (data, table) => {
    const { componentData, onChange } = this.props;
    const values = {
      data: {
        ...this.state,
      },
    };

    if (table === 'sources') {
      this.setState({ sourcesRows: data });
      values.data.sourcesRows = data;
    } else if (table === 'uses') {
      this.setState({ usesRows: data });
      values.data.usesRows = data;
    }

    onChange(componentData, values);
  };

  setSourcesAndUses = () => {
    const { componentData, editingComponentData } = this.props;
    let newState = this.state as State;
    if (componentData && componentData.data) {
      newState = componentData.data;
    }
    if (
      editingComponentData &&
      editingComponentData.id === componentData.id &&
      editingComponentData.data
    ) {
      newState = editingComponentData.data;
    }
    this.setState(newState);
  };

  render() {
    const { sourcesRows, sourcesSummary, usesRows, usesSummary } = this.state;
    const {
      active,
      componentData,
      editingComponentData,
      handleComponentLockStatus,
      handleLockComponent,
      onChange,
    } = this.props;

    return (
      <div className="overflow-hidden">
        <Container>
          <Row>
            <Col xs="6" className="col-left" data-testid="sourcesAndUsesLeft">
              {active && (
                <TableImport
                  componentId={`${componentData.id}-sources`}
                  handleImport={(data) => this.handleImport(data, 'sources')}
                  type="sources"
                />
              )}
              <GridTable
                active={active}
                title="Sources"
                rows={sourcesRows}
                onChange={(data) => this.onChange(data, 'sources')}
                summary={sourcesSummary}
                testId="sourcesTable"
              />
            </Col>
            <Col xs="6" className="col-right" data-testid="sourcesAndUsesRight">
              {active && (
                <TableImport
                  componentId={`${componentData.id}-uses`}
                  handleImport={(data) => this.handleImport(data, 'uses')}
                  type="uses"
                />
              )}
              <GridTable
                active={active}
                title="Uses"
                rows={usesRows}
                onChange={(data) => this.onChange(data, 'uses')}
                summary={usesSummary}
                testId="usesTable"
              />
            </Col>
          </Row>
        </Container>
        <Footnotes
          active={active}
          currentData={
            editingComponentData && editingComponentData.id === componentData.id
              ? editingComponentData
              : componentData
          }
          editingComponentData={editingComponentData}
          handleComponentLockStatus={handleComponentLockStatus}
          handleLockComponent={handleLockComponent}
          onChange={onChange}
        />
      </div>
    );
  }
}
