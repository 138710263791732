import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  isOpen: boolean;
  unpublishOffering: () => void;
  submitting: boolean;
  toggle: () => void;
};

export default class UnpublishOfferingModal extends React.Component<Props> {
  render() {
    const { isOpen, submitting, toggle, unpublishOffering } = this.props;

    return (
      <Modal centered isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Draft Offering</ModalHeader>
        <ModalBody>
          <p>
            You are about to draft a live offering. It will no longer be visible
            to the public. Are you sure you want to do this?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <ButtonPlus
            color="primary"
            loading={submitting}
            onClick={unpublishOffering}
            style={{ width: '200px' }}
            data-testid="unpublishSubmitButton"
          >
            Draft Offering
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
