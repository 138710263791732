import { Reducer } from 'redux';
import type { User } from 'src/types/User';

import { LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from '../../redux/actions/Auth';
import { BaseAction, BaseState } from '../types/Base';

type AuthState = BaseState & {
  user?: User;
};

export const initialState: AuthState = {};

const authReducer: Reducer<AuthState, BaseAction<null, AuthState>> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        submitting: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        submitting: false,
        user: action.user,
        error: undefined,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        submitting: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default authReducer;
