import './AddComponentModal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import type { ComponentType } from 'src/types/Component';

import ButtonPlus from '../../../components/ButtonPlus/ButtonPlus';

type Props = {
  addComponent: (type: string) => void;
  disabledComponentTypes: string[];
  isOpen: boolean;
  submitting: boolean;
  toggle: (
    parentId?: number,
    disabledComponentTypes?: ComponentType[],
    position?: number,
  ) => void;
};
type State = { selectedType: string };

export default class AddComponentModal extends React.Component<Props, State> {
  state = {
    selectedType: null,
  };

  handleButtonClick = (type: string) => {
    this.setState({ selectedType: type });
  };

  getLabel = (type: string) => {
    if (type === 'cash-flow') return 'Cash Flow Scenario';
    if (type === 'metrics') return 'Key Metrics';
    if (type === 'section-parallel') return 'Parallel Columns';
    if (type === 'sources-and-uses') return 'Sources And Uses';
    return type;
  };

  render() {
    const { addComponent, isOpen, disabledComponentTypes, submitting, toggle } =
      this.props;
    const { selectedType } = this.state;

    // These can be uncommented as they're implemented

    let componentTypes = [
      {
        type: 'section',
        icon: 'puzzle-piece',
      },
      {
        type: 'section-parallel',
        icon: 'pause',
      },
      {
        type: 'text',
        icon: 'font',
      },
      {
        type: 'image',
        icon: 'file-image',
      },
      {
        type: 'hero',
        icon: 'image',
      },
      {
        type: 'table',
        icon: 'table',
      },
      {
        type: 'map',
        icon: 'map-marker-alt',
      },
      {
        type: 'media',
        icon: 'play-circle',
      },
      {
        type: 'metrics',
        icon: 'percent',
      },
      {
        type: 'documents',
        icon: 'file-pdf',
      },
      {
        type: 'sources-and-uses',
        icon: 'columns',
      },
      {
        type: 'cash-flow',
        icon: 'chart-bar',
      },
    ];

    if (disabledComponentTypes) {
      componentTypes = componentTypes.filter(
        (component) => disabledComponentTypes.indexOf(component.type) === -1,
      );
    }

    return (
      <Modal
        centered
        isOpen={isOpen}
        onClosed={() => this.setState({ selectedType: null })}
        toggle={() => toggle()}
        id="add-component-modal"
        data-testid="addComponentModal"
      >
        <ModalHeader toggle={() => toggle()}>Add Component</ModalHeader>
        <ModalBody>
          <Row>
            {componentTypes.map((component) => (
              <Col xs="2" key={component.type}>
                <button
                  className={`component-button ${
                    selectedType === component.type ? 'active' : ''
                  }`}
                  onClick={() => this.handleButtonClick(component.type)}
                  title={`Add ${component.type} component`}
                  type="button"
                >
                  {/* @ts-expect-error we import every icon in App.js in order to use this as is */}
                  <FontAwesomeIcon icon={component.icon} />
                  <span>{this.getLabel(component.type)}</span>
                </button>
              </Col>
            ))}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => toggle()}>
            Cancel
          </Button>
          <ButtonPlus
            color="primary"
            disabled={selectedType ? false : true}
            loading={submitting}
            onClick={() => addComponent(selectedType)}
            style={{ width: '190px' }}
            data-testid="addComponentSubmitButton"
          >
            Add Component
          </ButtonPlus>
        </ModalFooter>
      </Modal>
    );
  }
}
