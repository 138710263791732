import React from 'react';
import type { Component } from 'src/types/Component';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  onChange: (component: Component, values: Component) => void;
};

export default class CollapseToggle extends React.Component<Props> {
  toggleCollapse = () => {
    const { componentData, editingComponentData, onChange } = this.props;
    const currentData = editingComponentData || componentData;

    const values = {
      collapse: !currentData.collapse,
    };

    onChange(componentData, values);
  };

  render() {
    const { componentData, editingComponentData } = this.props;
    const currentData =
      editingComponentData && editingComponentData.id === componentData.id
        ? editingComponentData
        : componentData;
    const collapse = currentData.collapse;

    return (
      <div id="collapse-toggle" className="clear-both">
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="collapse"
            checked={Boolean(collapse)}
            onChange={this.toggleCollapse}
          />
          <label htmlFor="collapse">Collapse component</label>
        </div>
      </div>
    );
  }
}
