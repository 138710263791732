import './OfferingsArchived.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import type { AppDispatch, RootState } from 'src';

import ButtonPlus from '../../components/ButtonPlus/ButtonPlus';
import Header from '../../components/Header/Header';
import Loading from '../../components/Loading/Loading';
import { getOfferings, unarchiveOffering } from '../../redux/actions/Offerings';
import { Offering } from '../../types/Offering';

type Props = {
  dispatch: AppDispatch;
  loading: boolean;
  submitting: boolean;
  offerings: Offering[];
};

class OfferingsArchived extends React.Component<Props> {
  componentDidMount() {
    document.title = 'Archived Offerings';
    this.props.dispatch(getOfferings());
  }

  handleUnarchiveOffering = (id: number) => {
    this.props.dispatch(unarchiveOffering(id)).then(() => {
      this.props.dispatch(getOfferings());
    });
  };

  render() {
    const { loading, offerings, submitting } = this.props;
    const archivedOfferings = [];
    if (offerings) {
      offerings.forEach((offering) => {
        if (offering.archived) archivedOfferings.push(offering);
      });
    }
    if (archivedOfferings.length > 0) {
      archivedOfferings.sort((a, b) => {
        return (
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      });
    }

    return (
      <div data-testid="offeringsArchived">
        {loading || !offerings ? (
          <Loading />
        ) : (
          <div id="archived-offerings">
            <Header
              title="Archived Offerings"
              backLink="/offerings"
              backLinkText="Offerings"
            />
            <Container>
              <div className="archived-offerings-list">
                {archivedOfferings.length === 0 ? (
                  <div className="no-offerings">
                    <p className="margin-xx text-center">
                      There are currently no archived offerings.
                    </p>
                  </div>
                ) : (
                  archivedOfferings &&
                  archivedOfferings.map((offering) => (
                    <div className="archived-offering" key={offering.id}>
                      <div className="title">{offering.name}</div>
                      <ButtonPlus
                        outline
                        color="primary"
                        onClick={() =>
                          this.handleUnarchiveOffering(offering.id)
                        }
                        loading={submitting}
                        style={{ width: '140px' }}
                        data-testid="unarchiveButton"
                      >
                        Un-archive
                      </ButtonPlus>
                    </div>
                  ))
                )}
              </div>
            </Container>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (store: RootState) => {
  return {
    loading: store.Offerings.loading,
    offerings: store.Offerings.offerings,
    submitting: store.Offerings.submitting,
  };
};

export default connect(mapStateToProps)(OfferingsArchived);
