import './KeyMetricsModal.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import type { Component, ComponentMetricData } from 'src/types/Component';
import type { MetricData } from 'src/types/MetricData';
import * as Yup from 'yup';

import ButtonPlus from '../../../../components/ButtonPlus/ButtonPlus';
import { cloneObject, midPoint, snakeCase } from '../../../../helpers';
import {
  keyMetricsHelpText,
  keyMetricsName,
  keyMetricsValue,
} from '../../../../regex';
import { icons } from '../helpers/constants';

type Props = {
  componentData: Component;
  editingComponentData: Component;
  handleAddOfferingData: (data: ComponentMetricData) => Promise<void>;
  handleGetOfferingData: (noLoading: true) => void;
  handleSaveOfferingData: (id: number, data: MetricData) => Promise<void>;
  isOpen: boolean;
  offeringData: MetricData[];
  onChange: (component: Component, values: Component) => void;
  metricSelected?: string;
  submitting: boolean;
  toggle: () => void;
};
type State = { selectedMetricHasConnectorId: boolean };

export default class KeyMetricsModal extends React.Component<Props, State> {
  state = {
    selectedMetricHasConnectorId: false,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen)
      this.props.handleGetOfferingData(true);
  }

  componentDataHasOfferingData = () => {
    const { componentData } = this.props;
    return componentData?.data?.omcms?.offeringData?.length > 0;
  };

  editingComponentDataHasOfferingData = () => {
    const { editingComponentData } = this.props;
    return editingComponentData?.data?.omcms?.offeringData;
  };

  schema = () => {
    const existingLabels = [];
    this.props.offeringData.forEach((metric) => {
      existingLabels.push(metric.label);
    });
    return Yup.object().shape({
      offeringData: Yup.string().required('Offering Data is required'),
      name: Yup.string()
        .required('Name is required')
        .max(128, 'Name must be less than 128 characters')
        .matches(keyMetricsName.pattern, {
          message: `Name ${keyMetricsName.description}`,
          excludeEmptyString: true,
        })
        .when('offeringData', {
          is: 'new',
          then: (schema) =>
            schema.notOneOf(
              existingLabels,
              'A metric with this name already exists, and can be selected from the dropdown above',
            ),
        }),
      value: Yup.string()
        .required('Value is required')
        .max(128, 'Value must be less than 128 characters')
        .matches(keyMetricsValue.pattern, {
          message: `Value ${keyMetricsValue.description}`,
          excludeEmptyString: true,
        }),
      helpText: Yup.string()
        .max(128, 'Help text must be less than 128 characters')
        .matches(keyMetricsHelpText.pattern, {
          message: `Help text ${keyMetricsHelpText.description}`,
          excludeEmptyString: true,
        }),
    });
  };

  handleSubmit = (values) => {
    const {
      componentData,
      editingComponentData,
      handleAddOfferingData,
      handleSaveOfferingData,
      metricSelected,
      offeringData,
      onChange,
    } = this.props;

    let metrics: ComponentMetricData[] = [];
    if (this.componentDataHasOfferingData())
      metrics = cloneObject(componentData.data.omcms.offeringData);
    if (this.editingComponentDataHasOfferingData())
      metrics = cloneObject(editingComponentData.data.omcms.offeringData);

    // Offering level data
    const metricData: MetricData = {
      key: snakeCase(values.name),
      type: 'string',
      label: values.name,
      content: values.value,
    };

    // Component level data
    const lastMetric = metrics[metrics.length - 1];
    const lastPos = lastMetric?.pos;
    const componentMetricData: ComponentMetricData = {
      key: metricData.key,
      pos: midPoint(lastPos),
    };

    if (values.icon !== '') componentMetricData.icon = values.icon;
    if (values.helpText !== '') componentMetricData.helpText = values.helpText;

    const metricSelectedIndex = metricSelected
      ? metrics.findIndex((metric) => metric.key === metricSelected)
      : null;

    // Original offering level data for this metric, used to determine whether content has changed and needs to be saved
    const originalOfferingData = offeringData.find(
      (data) => data.key === metricData.key,
    );

    const updateComponentData = () => {
      if (metricSelected) metrics[metricSelectedIndex] = componentMetricData;
      else metrics.push(componentMetricData);

      const data = {
        omcms: {
          offeringData: metrics,
        },
      };

      onChange(componentData, { data });
    };

    if (values.offeringData === 'new') {
      // Adding new offeringData, then updating component data
      handleAddOfferingData(metricData).then(() => {
        updateComponentData();
      });
    } else if (metricData.content !== originalOfferingData.content) {
      // Updating the value/content of existing offeringData, then updating component data
      handleSaveOfferingData(originalOfferingData.id, metricData).then(() => {
        updateComponentData();
      });
    } else {
      // Updating the component data only for an existing metric
      updateComponentData();
    }
  };

  renderError = (message) => <Alert color="danger">{message}</Alert>;

  render() {
    const {
      componentData,
      editingComponentData,
      isOpen,
      offeringData,
      metricSelected,
      submitting,
      toggle,
    } = this.props;
    let metricSelectedData = null;
    const { selectedMetricHasConnectorId } = this.state;

    if (metricSelected) {
      metricSelectedData = offeringData.find(
        (data) => data.key === metricSelected,
      );

      let metricSelectedComponentData = null;

      if (this.componentDataHasOfferingData()) {
        metricSelectedComponentData =
          componentData.data.omcms.offeringData.find(
            (data) => data.key === metricSelected,
          );
      }

      if (this.editingComponentDataHasOfferingData()) {
        metricSelectedComponentData =
          editingComponentData.data.omcms.offeringData.find(
            (data) => data.key === metricSelected,
          );
      }

      metricSelectedData.icon = metricSelectedComponentData.icon;
      metricSelectedData.helpText = metricSelectedComponentData.helpText;
    }

    return (
      <Modal
        centered
        className="key-metrics-modal"
        isOpen={isOpen}
        toggle={toggle}
        data-testid="keyMetricsModal"
      >
        <Formik
          initialValues={{
            offeringData: metricSelectedData ? metricSelectedData.id : 'new',
            name: metricSelectedData ? metricSelectedData.label : '',
            icon: metricSelectedData ? metricSelectedData.icon : '',
            value: metricSelectedData ? metricSelectedData.content : '',
            helpText: metricSelectedData ? metricSelectedData.helpText : '',
          }}
          validationSchema={this.schema}
          onSubmit={(values) => this.handleSubmit(values)}
          render={({ setFieldValue, values }) => {
            return (
              <Form
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <ModalHeader toggle={toggle}>
                  {metricSelected ? 'Edit' : 'Add'} Metric
                </ModalHeader>
                <ModalBody>
                  <label htmlFor="offeringData">Offering Data</label>
                  <Input
                    type="select"
                    name="offeringData"
                    id="offeringData"
                    onChange={(e) => {
                      const val = e.target.value;
                      setFieldValue('offeringData', val);
                      let name = '';
                      let value = '';
                      let selectedOfferingData = null;
                      if (val !== 'new') {
                        selectedOfferingData = offeringData.find(
                          (data) => data.id === parseInt(val),
                        );
                        name = selectedOfferingData.label;
                        value = selectedOfferingData.content;
                      }
                      setFieldValue('name', name);
                      setFieldValue('value', value);
                      if (selectedOfferingData?.connectorId)
                        this.setState({ selectedMetricHasConnectorId: true });
                      else
                        this.setState({ selectedMetricHasConnectorId: false });
                    }}
                    value={values.offeringData}
                    disabled={metricSelected ? true : false}
                  >
                    <option value="new">New..</option>
                    {offeringData.map((metric) => (
                      <option value={metric.id} key={metric.id}>
                        {metric.label}
                      </option>
                    ))}
                  </Input>
                  <ErrorMessage name="offeringData" render={this.renderError} />

                  <label htmlFor="name">Metric Name</label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    tag={Field}
                    readOnly={values.offeringData !== 'new'}
                  />
                  <ErrorMessage name="name" render={this.renderError} />

                  <label htmlFor="">Icon (optional)</label>
                  <div className="icon-select" data-testid="iconSelect">
                    {icons.map((icon) => {
                      const isActiveIcon = values?.icon === icon;
                      return (
                        <button
                          className={`icon-button ${
                            isActiveIcon ? 'active' : ''
                          }`}
                          key={icon}
                          onClick={() => {
                            if (isActiveIcon) setFieldValue('icon', '');
                            else setFieldValue('icon', icon);
                          }}
                          type="button"
                        >
                          <FontAwesomeIcon
                            /* @ts-expect-error we import every icon in App.js in order to use this as is */
                            icon={icon}
                            data-testid={isActiveIcon ? 'activeIcon' : ''}
                          />
                        </button>
                      );
                    })}
                  </div>

                  <label htmlFor="helpText">
                    Help Text
                    <div className="label-subtext">
                      Optional tooltip text explaining the metric name
                    </div>
                  </label>
                  <Input
                    id="helpText"
                    name="helpText"
                    type="text"
                    tag={Field}
                    data-testid="metricsHelpTextField"
                  />
                  <ErrorMessage name="helpText" render={this.renderError} />

                  <label htmlFor="value">Value</label>
                  <Input
                    id="value"
                    name="value"
                    type="text"
                    tag={Field}
                    readOnly={
                      selectedMetricHasConnectorId ||
                      (metricSelected && metricSelectedData.connectorId)
                    }
                  />
                  <ErrorMessage name="value" render={this.renderError} />
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  <ButtonPlus
                    color="primary"
                    loading={submitting}
                    type="submit"
                    style={{ width: '150px' }}
                  >
                    Save
                  </ButtonPlus>
                </ModalFooter>
              </Form>
            );
          }}
        />
      </Modal>
    );
  }
}
