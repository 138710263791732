import './InspectorMap.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import type { Component, ComponentLocation } from 'src/types/Component';

import { alphabet, midPoint } from '../../../../helpers';
import DeleteLocationModal from '../DeleteLocationModal';
import LocationModal from '../shared/LocationModal';

type Props = {
  submitting: boolean;
  onChange: (component: Component, values: Component) => void;
  selectedLocation: ComponentLocation;
  componentData: Component;
  handleMapUpdate?: (component: Component, values: Component) => void;
  editingComponentData: Component;
  handleLocationSelect: (location: ComponentLocation) => void;
  handleLockComponent: (id: number) => void;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
};
type State = {
  modalOpen: boolean;
  deleteLocationModalOpen: boolean;
};
export default class InspectorMap extends React.Component<Props, State> {
  state: State = {
    modalOpen: false,
    deleteLocationModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.editingComponentData !== this.props.editingComponentData)
      this.setState({ modalOpen: false, deleteLocationModalOpen: false });
  }

  handleDeleteLocation = () => {
    const { componentData, editingComponentData, selectedLocation, onChange } =
      this.props;

    const { data } = editingComponentData || componentData;

    const locations = data.locations.filter(
      (loc) => selectedLocation.name !== loc.name,
    );

    const values = {
      data: {
        locations: locations,
      },
    };
    onChange(componentData, values);
  };

  toggleDeleteLocationModal = async (_, location?: ComponentLocation) => {
    const {
      componentData,
      editingComponentData,
      handleLocationSelect,
      handleLockComponent,
      handleComponentLockStatus,
    } = this.props;

    if (location && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    handleLocationSelect(location);
    const newState = {
      deleteLocationModalOpen: !this.state.deleteLocationModalOpen,
    };
    this.setState(newState);
  };

  toggleModal = async (_, location?: ComponentLocation) => {
    const {
      componentData,
      editingComponentData,
      handleLocationSelect,
      handleLockComponent,
      handleComponentLockStatus,
    } = this.props;

    if ((!this.state.modalOpen || location) && !editingComponentData) {
      const isLocked = await handleComponentLockStatus(componentData.id);
      if (isLocked) {
        return;
      }
      handleLockComponent(componentData.id);
    }

    handleLocationSelect(location);
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };

  onDragStart = () => {
    const { componentData, editingComponentData, handleComponentLockStatus } =
      this.props;
    if (editingComponentData) {
      return;
    }

    handleComponentLockStatus(componentData.id);
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    const { componentData, editingComponentData, onChange } = this.props;
    const { data } = editingComponentData || componentData;

    if (!destination) {
      return;
    }

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const locations = data.locations.filter((loc) => draggableId !== loc.name);

    // using closest sibling's pos as start and end boundries for dropped node
    const nodeIndex = destination.index;
    const startPos = locations[nodeIndex - 1] && locations[nodeIndex - 1].pos;
    const endPos = locations[nodeIndex] && locations[nodeIndex].pos;

    // Set dragged location's new position
    const newLocations = data.locations.map((loc) => {
      if (loc.name === draggableId) loc.pos = midPoint(startPos, endPos);
      return loc;
    });

    // Sort locations by position
    newLocations.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });

    // Set alphabetical label based on index after sorting
    newLocations.forEach((loc, index) => {
      loc.label = alphabet(index);
    });

    const values = {
      data: {
        locations: newLocations,
      },
    };

    onChange(componentData, values);
  };

  render() {
    const {
      componentData,
      editingComponentData,
      handleLocationSelect,
      selectedLocation,
      submitting,
    } = this.props;
    const { modalOpen, deleteLocationModalOpen } = this.state;

    let locations = [];
    if (componentData.data) locations = componentData.data.locations;

    if (editingComponentData && editingComponentData.id === componentData.id) {
      locations = editingComponentData.data.locations;
    }

    locations.sort((a, b) => {
      if (a.pos < b.pos) {
        return -1;
      }
      if (a.pos > b.pos) {
        return 1;
      }
      return 0;
    });

    return (
      <div>
        <button
          className="add-button"
          onClick={this.toggleModal}
          title="Add Location"
          type="button"
        >
          <FontAwesomeIcon icon="plus" />
        </button>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="inspector-fields"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {locations &&
                  locations.length > 0 &&
                  locations.map((location, index) => (
                    <Draggable
                      key={location.name}
                      draggableId={location.name}
                      index={index}
                    >
                      {(draggableProvided, item) => (
                        <div
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          onClick={() => handleLocationSelect(location)}
                          ref={draggableProvided.innerRef}
                          key={location.label}
                          className={`selectable-list-item${
                            item.isDragging ||
                            (selectedLocation &&
                              selectedLocation.name === location.name)
                              ? ' active'
                              : ''
                          }`}
                          data-testid={`location${index}`}
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          <FontAwesomeIcon
                            className="component-icon"
                            icon="map-marker-alt"
                          />
                          <span className="label">{location.name}</span>
                          <button
                            className="edit"
                            onClick={(_) => this.toggleModal(_, location)}
                            data-testid={`editLocation${index}`}
                            type="button"
                          >
                            <FontAwesomeIcon
                              className="component-icon"
                              icon="pencil-alt"
                            />
                          </button>
                          <button
                            className="delete"
                            onClick={(_) =>
                              this.toggleDeleteLocationModal(_, location)
                            }
                            data-testid={`deleteLocation${index}`}
                            type="button"
                          >
                            ×
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <LocationModal
          {...this.props}
          isOpen={modalOpen}
          toggle={this.toggleModal}
          editingLocation={selectedLocation}
        />
        <DeleteLocationModal
          submitting={submitting}
          deleteLocation={this.handleDeleteLocation}
          isOpen={deleteLocationModalOpen}
          toggle={this.toggleDeleteLocationModal}
        />
      </div>
    );
  }
}
