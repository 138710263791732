import JwtDecode from 'jwt-decode';
import { removeAll } from 'react-notification-system-redux';
import { Action, ActionCreator } from 'redux';
import { push } from 'redux-first-history';
import type { AppDispatch } from 'src';
import type { User } from 'src/types/User';

import apiClient from '../../apiClient'; // eslint-disable-line
import { clearUserToken, setUserToken } from '../../helpers';
import { BaseAction } from '../types/Base';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const CLEAR_AUTH = 'CLEAR_AUTH';

interface Error {
  response: {
    data: {
      statusCode: number;
      message: string;
      data?: {
        message: string;
      };
    };
  };
}

/**
 * Stores JWT (as access token), decodes it, returns decoded data
 * @param {string} userToken JWT
 */
export const processAccessToken = (userToken: string) => {
  setUserToken(userToken); // Store token for future requests
  const decodedUserData = JwtDecode(userToken);
  return decodedUserData;
};

export const logoutSuccess: ActionCreator<Action> = () => {
  return {
    type: LOGOUT,
  };
};

export const loginStart: ActionCreator<Action> = () => {
  return {
    type: LOGIN,
  };
};

export const loginSuccess: ActionCreator<BaseAction<null, { user: User }>> = (
  user,
) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

export const loginFail: ActionCreator<BaseAction> = (error) => {
  return {
    type: LOGIN_FAIL,
    error,
  };
};

export const logout = () => {
  return (dispatch: AppDispatch) => {
    clearUserToken(); // To reset user state (outside of redux)
    dispatch(push('/login')); // Return to login screen (must dispatch before resetting state)
    dispatch(logoutSuccess()); // Reset app state (don't bleed data across user sessions)
  };
};

export const submitLogin = (email: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loginStart());
    try {
      // Fetch and set user access token
      const response = await apiClient.post('/authoring/auth', {
        email,
        password,
      });

      // Decode access token and persist user data in redux. If token cannot
      // be decoded, fail login (as authentic OMCMS tokens should be valid and usable OOTB)
      try {
        const decodedUserData = processAccessToken(response.data.token);
        dispatch(removeAll()); // Clear all open app notifications
        dispatch(loginSuccess(decodedUserData)); // Load user data into redux
        dispatch(push('/offerings')); // Load app
      } catch (err) {
        dispatch(loginFail('Error validating user token'));
      }
    } catch (error) {
      // TODO simplify this absurd error detail protocol OM-385
      const baseResponse = (error as Error)?.response?.data;
      const statusCode = baseResponse?.statusCode;
      let errorMessage = baseResponse?.message || baseResponse?.data?.message;
      if (statusCode === 401) {
        errorMessage = JSON.stringify(baseResponse?.data);
      }

      if (!errorMessage) {
        errorMessage = 'There was an issue connecting to the server.';
      }

      dispatch(loginFail(errorMessage));
    }
  };
};

export const submitGoogleLogin = (googleIdToken: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(loginStart());
    try {
      // Fetch and set user access token
      const response = await apiClient.post('/authoring/auth/google', {
        token: googleIdToken,
      });
      // Decode access token and persist user data in redux. If token cannot
      // be decoded, fail login (as authentic OMCMS tokens should be valid and usable OOTB)
      try {
        const decodedUserData = processAccessToken(response.data.token);
        dispatch(removeAll()); // Clear all open app notifications
        dispatch(loginSuccess(decodedUserData)); // Load user data into redux
        dispatch(push('/offerings')); // Load app
      } catch (err) {
        dispatch(loginFail('Error validating user token'));
      }
    } catch (error) {
      // TODO simplify this absurd error detail protocol OM-385
      const baseResponse = (error as Error)?.response?.data;
      let errorMessage = baseResponse?.message || baseResponse?.data?.message;

      if (!errorMessage) {
        errorMessage = 'There was an issue connecting to the server.';
      }

      dispatch(loginFail(errorMessage));
    }
  };
};
