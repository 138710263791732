import './ErrorBoundary.scss';

import React, { PropsWithChildren } from 'react';
import { Container } from 'reactstrap';

export type ServerError = {
  name: string;
  message: string;
};

type Props = PropsWithChildren & {
  serverError?: ServerError;
};

type State = {
  clientError: Error;
  clientErrorInfo: React.ErrorInfo;
};

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    clientError: null,
    clientErrorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      clientError: error,
      clientErrorInfo: errorInfo,
    });
  }

  render() {
    const { children, serverError } = this.props;
    const { clientError, clientErrorInfo } = this.state;
    if (clientError || serverError) {
      return (
        <Container id="error-boundary" data-testid="errorBoundary">
          <h3>Something went wrong. You can refresh the page to try again.</h3>
          {
            // Server errors
            serverError && (
              <div>
                <h5>{serverError.name}</h5>
                <p>{serverError.message}</p>
              </div>
            )
          }
          {
            // JS errors
            clientError && (
              <div>
                <h5>Error Information:</h5>
                <details>
                  <pre>
                    <strong>{clientError && clientError.toString()}</strong>
                    <br />
                    <span>
                      {clientErrorInfo && clientErrorInfo.componentStack}
                    </span>
                  </pre>
                </details>
              </div>
            )
          }
        </Container>
      );
    }
    return children;
  }
}
