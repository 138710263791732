import './TableImport.scss';

import React from 'react';
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from 'reactstrap';
import { getEventTransfer } from 'slate-react';

import ButtonPlus from '../../../../components/ButtonPlus/ButtonPlus';

export type TableType = 'sources' | 'uses' | 'cashflow' | 'table';

type Props = {
  componentId: number | string;
  handleImport: (
    tableData: Record<string, unknown>,
    firstRowBold: boolean,
    lastRowBold: boolean,
    firstColumnBold: boolean,
  ) => void;
  type: TableType;
};
type State = {
  error: string;
  modalOpen: boolean;
  firstRowBold: boolean;
  lastRowBold: boolean;
  loading: boolean;
  firstColumnBold: boolean;
  tableData: Record<string, unknown>;
  tooltipOpen: boolean;
};
export default class TableImport extends React.Component<Props, State> {
  state = {
    error: null,
    modalOpen: false,
    firstRowBold: false,
    lastRowBold: false,
    loading: false,
    firstColumnBold: false,
    tableData: null,
    tooltipOpen: false,
  };

  handleChange = (e) => {
    this.setState({ error: null });
    // Get the HTML from the clipboard, parse it, and return the first table element, ignoring everything else
    const transfer = getEventTransfer(e);
    const parsedHtml = new DOMParser().parseFromString(
      transfer.html,
      'text/html',
    );
    let table;
    const tables = parsedHtml.getElementsByTagName('table');
    if (tables.length > 0) {
      table = tables[0];

      // Remove thead, tbody, tfoot, th
      this.removeTableHeadBodyFoot(table);

      // Check if the first column/row, or last row is bold and apply the header/summary setting if so
      this.setHeaderAndSummary(table);

      // Remove spaces in HTML (from Excel and probably other sources) that prevent importing
      this.removeTableSpaces(table);

      this.setState({ tableData: table.outerHTML });
    } else {
      this.setState({
        error: 'The imported data does not appear to contain a table.',
      });
    }
  };

  removeTableHeadBodyFoot = (table) => {
    const elementsToRemove = ['thead', 'tbody', 'tfoot'];

    // Remove thead, tbody, tfoot while retaining child nodes
    elementsToRemove.forEach((element) => {
      const elementsArray = [...table.querySelectorAll(element)];
      if (elementsArray.length > 0) {
        elementsArray.forEach((elementInstance) => {
          elementInstance.replaceWith(...elementInstance.childNodes);
        });
      }
    });

    // Replace th with td
    [...table.querySelectorAll('th')].forEach(
      (th) =>
        (th.outerHTML = th.outerHTML
          .replace(/<th([^>]*)>/, '<td$1>')
          .replace(/<\/th>/, '</td>')),
    );
  };

  removeTableSpaces = (table) => {
    // Replace spaces in the inner HTML
    table.innerHTML = table.innerHTML
      .replace(/\n|\t/g, '')
      .replace(/> *</g, '><');
    // Set the outer HTML to a table element wrapping the inner HTML
    table.outerHTML = `<table>${table.innerHTML}</table>`;
  };

  setHeaderAndSummary = (table) => {
    const firstRowCells = [...table.querySelectorAll('tr:first-of-type>td')];
    const lastRowCells = [...table.querySelectorAll('tr:last-of-type>td')];
    const firstColumnCells = [...table.querySelectorAll('td:first-of-type')];

    // Check if tds all have bold text. If so, set the correct header/summary option into state
    const firstRowBold = this.allCellsAreBold(firstRowCells);
    const lastRowBold = this.allCellsAreBold(lastRowCells);
    const firstColumnBold = this.allCellsAreBold(firstColumnCells);

    const newState = {
      firstRowBold,
      lastRowBold,
      firstColumnBold,
    };

    this.setState(newState);

    // Remove the bold styles/markup from rows/columns that will have the header/summary options applied
    if (firstRowBold || lastRowBold || firstColumnBold)
      this.removeBoldFromTable(
        table,
        firstRowBold,
        lastRowBold,
        firstColumnBold,
      );
  };

  isBold = (element) => {
    return element?.style?.fontWeight && Number(element.style.fontWeight) > 500;
  };

  allCellsAreBold = (cells) => {
    // Returns true if all cells that aren't empty are bold

    // Used to return false when all cells are empty
    let allCellsAreEmpty = true;

    const everyCellIsBoldOrEmpty = cells.every((cell) => {
      const innerText = cell.innerText?.trim();
      if (!innerText) {
        return true;
      } else {
        allCellsAreEmpty = false;
        // Get all descendants of the cell
        const children = [...cell.getElementsByTagName('*')];

        const cellIsBold =
          this.isBold(cell) ||
          children.some((element) => this.isBold(element)) ||
          cell.querySelector('strong') != null ||
          cell.querySelector('b') != null;

        return cellIsBold;
      }
    });

    return !allCellsAreEmpty && everyCellIsBoldOrEmpty;
  };

  removeBoldFromTable = (table, firstRowBold, lastRowBold, firstColumnBold) => {
    // Removes bold formatting from header/summary rows/columns
    if (firstRowBold) {
      const firstRowCells = [...table.querySelectorAll('tr:first-of-type>td')];
      this.removeBoldFromCells(firstRowCells);
    }
    if (lastRowBold) {
      const lastRowCells = [...table.querySelectorAll('tr:last-of-type>td')];
      this.removeBoldFromCells(lastRowCells);
    }
    if (firstColumnBold) {
      const firstColumnCells = [...table.querySelectorAll('td:first-of-type')];
      this.removeBoldFromCells(firstColumnCells);
    }
  };

  removeBoldFromCells = (cells) => {
    // Removes bold formatting from individual cells
    cells.forEach((cell) => {
      // Remove cell font weight
      if (cell.style && cell.style.fontWeight) cell.style.fontWeight = null;
      const children = [...cell.getElementsByTagName('*')];
      if (children) {
        children.forEach((child) => {
          // If the cell has child elements, remove font weight and replace bold tags with spans
          if (child.style && child.style.fontWeight)
            child.style.fontWeight = null;
          if (['STRONG', 'B'].includes(child.tagName)) {
            // Replace bold tags with spans, so we can retain any other inline styles that may be wanted
            child.outerHTML = child.outerHTML
              .replace(/<strong([^>]*)>/, '<span$1>')
              .replace(/<\/strong>/, '</span>')
              .replace(/<b([^>]*)>/, '<span$1>')
              .replace(/<\/b>/, '</span>');
          }
        });
      }
    });
  };

  clearModal = () => {
    this.setState({
      firstColumnBold: false,
      firstRowBold: false,
      lastRowBold: false,
      loading: false,
      modalOpen: false,
      tableData: null,
    });
  };

  handleSubmit = () => {
    const { handleImport } = this.props;
    const { tableData, firstRowBold, lastRowBold, firstColumnBold } =
      this.state;
    this.setState({ loading: true });
    // Ensure the loading div is shown before import starts if a large import is going to lock up the page
    setTimeout(() => {
      handleImport(tableData, firstRowBold, lastRowBold, firstColumnBold);
      this.clearModal();
    }, 1);
  };

  toggleModal = () => {
    const { modalOpen } = this.state;
    this.setState({ modalOpen: !modalOpen });
  };

  render() {
    const { componentId, type } = this.props;
    const { error, loading, modalOpen, tableData, tooltipOpen } = this.state;
    const importText = 'Import ' + type + ' data';
    let instructions;
    switch (type as string) {
      case 'cashflow':
        instructions =
          ' Cash flow tables expect two columns, column A containing principal and column B earnings.';
        break;
      case 'sources':
      case 'uses':
        instructions =
          ' Sources and uses tables expect two columns, column A containing name and column B amount.';
        break;
      default:
        instructions = '';
    }

    return (
      <div className="table-import">
        <Button
          className="button-outline-secondary"
          outline
          size="sm"
          onClick={this.toggleModal}
          title={importText}
          data-testid={`${type}ImportButton`}
        >
          {importText}
        </Button>
        <div
          className="tooltip-trigger"
          id={`trigger-import-tooltip-${componentId}`}
        >
          (beta)
        </div>
        <Tooltip
          isOpen={tooltipOpen}
          placement="top"
          target={`trigger-import-tooltip-${componentId}`}
          trigger="hover"
          toggle={() => this.setState({ tooltipOpen: !tooltipOpen })}
        >
          This functionality is in early beta. For best results, please ensure
          that your data is correctly formatted before importing.
        </Tooltip>
        <Modal
          className="table-import-modal"
          centered
          isOpen={modalOpen}
          toggle={this.toggleModal}
          data-testid={`${type}ImportModal`}
        >
          <ModalHeader toggle={this.toggleModal}>{importText}</ModalHeader>
          <ModalBody>
            {
              // spans are for testing purposes
            }
            <p>
              <span>
                Copy table data from source and paste into the text box below.
              </span>
              <span>{instructions}</span>
              <br />
              <span>This will overwrite any existing table data.</span>
            </p>

            <p>
              In the case of large tables, the page may freeze temporarily while
              importing.
            </p>

            {!tableData ? (
              <div>
                <label htmlFor="table-data-textarea">Table Data</label>
                <textarea
                  name="table-data-textarea"
                  id="table-data-textarea"
                  className="table-data-textarea"
                  onChange={() => null} // Avoid no onChange warning, since we only want to use onPaste here
                  onPaste={(e) => this.handleChange(e)}
                  value={tableData || ''}
                />
              </div>
            ) : (
              <Alert color="info">
                Data added. Click import to attempt to import into the table, or
                cancel to abort.
              </Alert>
            )}
            {error && (
              <Alert color="danger" className="margin-top-x">
                {error}
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.clearModal}>
              Cancel
            </Button>
            <ButtonPlus
              color="primary"
              type="submit"
              loading={loading}
              onClick={this.handleSubmit}
              disabled={!tableData}
              style={{ width: '120px' }}
            >
              Import
            </ButtonPlus>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
