import './UserMenu.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import type { User } from 'src/types/User';

type State = {
  open: boolean;
};
type Props = {
  onLogout: () => void;
  user: User;
};

export default class UserMenu extends React.Component<Props, State> {
  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { onLogout, user } = this.props;
    const { open } = this.state;
    let userLetter = '?';
    if (user?.name) userLetter = user.name.charAt(0);
    return (
      <Dropdown
        id="user-menu"
        data-testid="userMenu"
        isOpen={open}
        toggle={this.toggleMenu}
      >
        <DropdownToggle className="user-link">
          <span>{userLetter}</span>
        </DropdownToggle>
        <DropdownMenu right data-testid="userMenuCollapsible">
          <DropdownItem className="user-info">
            <div>{user?.name}</div>
            <div>{user?.email}</div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <Link to="/connectors">
              Connectors
              <FontAwesomeIcon icon="network-wired" />
            </Link>
          </DropdownItem>
          <DropdownItem className="logout" onClick={onLogout}>
            Log Out
            <FontAwesomeIcon icon="sign-out-alt" />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}
