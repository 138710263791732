import './Footnotes.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Value } from 'slate';
import { Editor, getEventTransfer } from 'slate-react';
import type { Component } from 'src/types/Component';

import { debounce } from '../../../../helpers';
import {
  initialSlateTextContent,
  slateTextHtmlSerializer,
  slateTextSchema,
} from '../helpers/slate';
import DeleteConfirmationModal from './DeleteConfirmationModal';

type Props = {
  active: boolean;
  currentData: Component;
  editingComponentData: Component;
  handleComponentLockStatus: (id: number) => Promise<boolean>;
  handleLockComponent: (id: number) => void;
  onChange: (current: Component, data: Component) => void;
};

export default class Footnotes extends React.Component<Props> {
  componentWrap = React.createRef<HTMLDivElement>();

  editor = React.createRef<Editor>();

  initialValue = Value.fromJSON(
    this.props.currentData?.data?.omcms?.footnotes?.editorData?.content
      ?.document
      ? this.props.currentData.data.omcms.footnotes.editorData.content
      : initialSlateTextContent,
  );

  state = {
    deleteModalOpen: false,
    fixedToolbar: false,
    showFootnotes: false,
    value: this.initialValue,
  };

  componentDidMount() {
    const { currentData } = this.props;
    if (currentData?.data?.omcms?.footnotes?.editorData?.content)
      this.setState({ showFootnotes: true });
  }

  componentDidUpdate(prevProps) {
    const { active } = this.props;
    if (!prevProps.active && active) {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    }
    if (prevProps.active && !active)
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { fixedToolbar } = this.state;
    const offsetTop = this.componentWrap.current
      ? this.componentWrap.current.getBoundingClientRect().top
      : null;
    const offsetBottom = this.componentWrap.current
      ? this.componentWrap.current.getBoundingClientRect().bottom
      : null;
    const navHeight = 56;
    const editorInView = offsetTop < navHeight && offsetBottom > 200;
    if (!fixedToolbar && editorInView) this.setState({ fixedToolbar: true });
    if (fixedToolbar && !editorInView) this.setState({ fixedToolbar: false });
  };

  handleChange = debounce((value) => {
    const { currentData, onChange } = this.props;
    const currentOmcmsData = currentData.data ? currentData.data.omcms : null;

    const values = {
      data: {
        omcms: {
          ...currentOmcmsData,
          footnotes: {
            editorData: {
              content: value ? value.toJSON() : null,
              output: value ? slateTextHtmlSerializer.serialize(value) : null,
            },
          },
        },
      },
    };

    onChange(currentData, values);
  }, 100);

  handleClickBlock = (e, type) => {
    e.preventDefault();

    const editor = this.editor.current;
    const { value } = editor;
    const { document } = value;

    // Handle everything but list buttons
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        editor
          .setBlocks(isActive ? 'paragraph' : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (type === 'tab') {
        editor.insertInline({
          object: 'inline',
          type: 'tab',
          nodes: [],
        });
      } else {
        editor.setBlocks(isActive ? 'paragraph' : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons
      const isList = this.hasBlock('list-item');
      const isType = value.blocks.some((block) => {
        return !!document.getClosest(
          block.key,
          (parent) => parent.type === type,
        );
      });

      if (isList && isType) {
        editor
          .setBlocks('paragraph')
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        editor
          .unwrapBlock(
            type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list',
          )
          .wrapBlock(type);
      } else {
        editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  };

  handleClickLink = (e) => {
    e.preventDefault();
    const editor = this.editor.current;
    const { value } = editor;

    if (this.hasLinks()) {
      editor.command(this.unwrapLink);
    } else if (value.selection.isExpanded) {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      editor.command(this.wrapLink, href);
    } else {
      const href = window.prompt('Enter the URL of the link:');
      if (href == null) return;

      const text = window.prompt('Enter the text for the link:');

      if (text == null) return;

      editor
        .insertText(text)
        .moveFocusBackward(text.length)
        .command(this.wrapLink, href);
    }
  };

  handleClickMark = (e, type) => {
    e.preventDefault();
    this.editor.current.toggleMark(type);
  };

  handlePaste = (event, editor, next) => {
    const transfer = getEventTransfer(event);
    if (transfer.type !== 'html') return next();
    const { document } = slateTextHtmlSerializer.deserialize(transfer.html);
    editor.insertFragment(document);
  };

  hasMark = (type) =>
    this.state.value.activeMarks.some((mark) => mark.type === type);

  hasBlock = (type) =>
    this.state.value.blocks.some((node) => node.type === type);

  hasLinks = () =>
    this.state.value.inlines.some((inline) => inline.type === 'link');

  wrapLink = (editor, href) => {
    editor.wrapInline({
      type: 'link',
      data: { href },
    });
    editor.moveToEnd();
  };

  unwrapLink = (editor) => {
    editor.unwrapInline('link');
  };

  renderBlock = (props, _, next) => {
    const { attributes, children, node } = props;
    const className = node.data.get('className');
    if (className) attributes.className = className;

    switch (node.type) {
      case 'paragraph':
        return <p {...attributes}>{children}</p>;
      case 'quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      default:
        return next();
    }
  };

  renderInline = (props, _, next) => {
    const { attributes, children, node } = props;

    switch (node.type) {
      case 'link':
        const { data } = node;
        const href = data.get('href');
        return (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            {...attributes}
          >
            {children}
          </a>
        );
      case 'tab':
        return <span {...attributes}>&emsp;{children}</span>;
      default:
        return next();
    }
  };

  renderMark = (props, _, next) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underline':
        return <u {...attributes}>{children}</u>;
      case 'strikethrough':
        return <s {...attributes}>{children}</s>;
      case 'superscript':
        return <sup {...attributes}>{children}</sup>;
      default:
        return next();
    }
  };

  renderBlockButton = (type, icon) => {
    let active = this.hasBlock(type);

    if (['numbered-list', 'bulleted-list'].includes(type)) {
      const {
        value: { document, blocks },
      } = this.state;

      if (blocks.size > 0) {
        const parent = document.getParent(blocks.first().key);
        active = this.hasBlock('list-item') && parent && parent.type === type;
      }
    }

    return (
      <button
        className={`toggle-button button-${type} ${active ? 'active' : ''}`}
        onMouseDown={(event) => this.handleClickBlock(event, type)}
        title={type}
        type="button"
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

  renderMarkButton = (type, icon) => (
    <button
      className={`toggle-button ${this.hasMark(type) ? 'active' : ''}`}
      onMouseDown={(event) => this.handleClickMark(event, type)}
      title={type}
      type="button"
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );

  handleDelete = () => {
    this.handleChange(null);
    this.setState({
      deleteModalOpen: false,
      showFootnotes: false,
      value: Value.fromJSON(initialSlateTextContent),
    });
  };

  showFootnotes = () => {
    this.setState({ showFootnotes: true });
  };

  removeFootnotes = () => {
    const { currentData } = this.props;
    if (currentData.data?.omcms?.footnotes) this.toggleDeleteModal();
    else this.setState({ showFootnotes: false });
  };

  toggleDeleteModal = async () => {
    const {
      currentData,
      editingComponentData,
      handleLockComponent,
      handleComponentLockStatus,
    } = this.props;

    if (!editingComponentData) {
      const isLocked = await handleComponentLockStatus(currentData.id);
      if (isLocked) return;
      handleLockComponent(currentData.id);
    }

    this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
  };

  render() {
    const { active, currentData } = this.props;
    const { deleteModalOpen, fixedToolbar, showFootnotes } = this.state;
    const componentId = currentData.id.toString();

    return (
      <div className="footnotes">
        {active && (
          <div className="footnotes-header">
            <h6>Footnotes</h6>
            {!showFootnotes ? (
              <button
                className="footnotes-add"
                onClick={this.showFootnotes}
                title="Add footnotes"
                type="button"
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            ) : (
              <button
                className="footnotes-remove"
                onClick={this.removeFootnotes}
                title="Remove footnotes"
                type="button"
              >
                <FontAwesomeIcon icon="plus" />
              </button>
            )}
          </div>
        )}
        {showFootnotes && (
          <div className="footnotes-content" data-testid="footnotesContent">
            <div
              className={`overflow-hidden ${
                fixedToolbar ? 'fixed-toolbar' : ''
              }`}
              ref={this.componentWrap}
            >
              {active && (
                <div
                  className="editor-toolbar"
                  data-testid="footnotesEditorToolbar"
                >
                  {this.renderMarkButton('bold', 'bold')}
                  {this.renderMarkButton('italic', 'italic')}
                  {this.renderMarkButton('underline', 'underline')}
                  {this.renderMarkButton('strikethrough', 'strikethrough')}
                  {this.renderMarkButton('superscript', 'superscript')}
                  <button
                    className={`toggle-button ${
                      this.hasLinks() ? 'active' : ''
                    }`}
                    onMouseDown={this.handleClickLink}
                    title="hyperlink"
                    type="button"
                  >
                    <FontAwesomeIcon icon="link" />
                  </button>
                  {this.renderBlockButton('quote', 'quote-left')}
                  {this.renderBlockButton('numbered-list', 'list-ol')}
                  {this.renderBlockButton('bulleted-list', 'list-ul')}
                  {this.renderBlockButton('tab', 'long-arrow-alt-right')}
                </div>
              )}
              <div data-testid={`footnotesEditor${componentId}`}>
                <Editor
                  autoFocus={active}
                  className="editor-footnotes"
                  id={`editor-footnotes-${componentId}`}
                  onChange={({ value }) => {
                    if (value.document !== this.state.value.document)
                      this.handleChange(value);
                    this.setState({ value });
                  }}
                  onPaste={this.handlePaste}
                  placeholder=""
                  renderBlock={this.renderBlock}
                  renderInline={this.renderInline}
                  renderMark={this.renderMark}
                  ref={this.editor}
                  readOnly={!active}
                  schema={slateTextSchema}
                  value={this.state.value}
                />
              </div>
            </div>
          </div>
        )}
        <DeleteConfirmationModal
          handleDelete={this.handleDelete}
          isOpen={deleteModalOpen}
          toggle={this.toggleDeleteModal}
          type="footnotes"
        />
      </div>
    );
  }
}
