import './NothingFound.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

export default class NothingFound extends React.Component {
  componentDidMount() {
    document.title = 'Nothing Found';
  }

  render() {
    return (
      <Container
        id="nothing-found"
        data-testid="nothingFound"
        className="container flex-vertical-center flex-horizontal-center text-center"
      >
        <h2>Nothing could be found at this location</h2>
        <Link to="/">Back to Home</Link>
      </Container>
    );
  }
}
