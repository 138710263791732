import axios from 'axios';
import { error as errorMsg } from 'react-notification-system-redux';

import { apiUrl } from './constants';
import { getUserToken } from './helpers';
import { logout } from './redux/actions/Auth'; // eslint-disable-line
import { setError } from './redux/actions/Error';

const apiClient = axios.create({
  baseURL: apiUrl,
});

const notificationOpts = {
  title: 'Error',
  message: 'An error was detected.',
  autoDismiss: 10,
};

/**
 * Sets up common request middleware
 * @param {*} store
 * @param {*} history
 */
export const setupInterceptors = (store) => {
  /**
   * Middleware which attaches user access tokens to requests. Does not set the
   * 'Authorization' header when token does not exist
   */
  apiClient.interceptors.request.use((config) => {
    const authToken = getUserToken();
    if (authToken != null) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  });

  /**
   * Error response handler. Actions for common error types, e.g. 401, 500, 400
   */
  apiClient.interceptors.response.use(
    // No interceptor for valid response
    undefined,
    (error) => {
      const response = error.response ? error.response.data : null;
      let showNotification = true;
      if (!response) {
        console.log(error);
        // Network error
        notificationOpts.title = 'Unable to connect to server';
        notificationOpts.message =
          'There was an issue communicating with the server. Please refresh to try again.';
        notificationOpts.autoDismiss = 0;
      } else {
        if (response.statusCode === 500) {
          showNotification = false;
          store.dispatch(setError(response));
        } else if (response.statusCode === 401) {
          notificationOpts.autoDismiss = 0;
          store.dispatch(logout());
        } else if (response.statusCode === 409 || response.statusCode === 400) {
          notificationOpts.title = response.name;
          notificationOpts.message = response.message;
        } else if (response.name === 'InternalError') {
          notificationOpts.message = response.message;
        } else {
          console.log('Error Data:', response);
          notificationOpts.title = response.status + ' Error';
          if (Array.isArray(response.data)) {
            notificationOpts.message = '';
            response.data.forEach((errorObj, index) => {
              notificationOpts.message += errorObj.message;
              if (index !== response.data.length - 1)
                notificationOpts.message += '\n\n';
            });
          } else if (response.data) {
            notificationOpts.message = response.data.message;
          } else if (response.name && response.statusCode) {
            notificationOpts.message =
              response.statusCode + ' - ' + response.name;
          }
        }
      }
      // Don't show on login page to prevent erroneous 401
      if (window.location.href.indexOf('/login') === -1 && showNotification)
        store.dispatch(errorMsg(notificationOpts));
      return Promise.reject(error);
    },
  );
};

export default apiClient;
