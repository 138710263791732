import { success } from 'react-notification-system-redux';
import { Action, ActionCreator } from 'redux';

import type { AppDispatch } from '../..';
import apiClient from '../../apiClient';
import type { BaseAction } from '../types/Base';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const signUpStart: ActionCreator<Action> = () => {
  return {
    type: REGISTER_USER,
  };
};

export const signUpSuccess: ActionCreator<BaseAction> = (values?) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: values,
  };
};

export const signUpFail: ActionCreator<BaseAction> = (error) => {
  const message =
    error?.response?.data?.message ||
    'There was an issue connecting to the server.';

  return {
    type: REGISTER_USER_FAIL,
    error: message,
  };
};

export const submitSignUp = (values: { key?: string }) => {
  return (dispatch: AppDispatch) => {
    dispatch(signUpStart());
    if (process.env.REACT_APP_HIDE_REGISTRATION_LINK === 'true') {
      throw new Error('Signup not allowed');
    } else {
      return apiClient
        .post('/authoring/users', values)
        .then(() => {
          dispatch(
            success({
              title: 'Account created',
              message: 'You can now log in',
            }),
          );
          return dispatch(signUpSuccess());
        })
        .catch((error) => {
          dispatch(signUpFail(error));
          throw error;
        });
    }
  };
};
