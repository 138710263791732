import { Reducer } from 'redux';
import type { Offering } from 'src/types/Offering';

import {
  ADD_OFFERING,
  ADD_OFFERING_FAIL,
  ADD_OFFERING_SUCCESS,
  ARCHIVE_OFFERING,
  ARCHIVE_OFFERING_FAIL,
  ARCHIVE_OFFERING_SUCCESS,
  CLONE_OFFERING,
  CLONE_OFFERING_FAIL,
  CLONE_OFFERING_SUCCESS,
  GET_OFFERINGS,
  GET_OFFERINGS_FAIL,
  GET_OFFERINGS_SUCCESS,
  PUBLISH_OFFERING,
  PUBLISH_OFFERING_FAIL,
  PUBLISH_OFFERING_SUCCESS,
  UNARCHIVE_OFFERING,
  UNARCHIVE_OFFERING_FAIL,
  UNARCHIVE_OFFERING_SUCCESS,
  UNPUBLISH_OFFERING,
  UNPUBLISH_OFFERING_FAIL,
  UNPUBLISH_OFFERING_SUCCESS,
} from '../../redux/actions/Offerings';
import { BaseAction, BaseState } from '../types/Base';

type OfferingsState = BaseState & {
  offerings?: Offering[];
};

export const initialState = {
  loading: false,
  offerings: undefined,
  submitting: false,
};

const offeringsReducer: Reducer<
  OfferingsState,
  BaseAction<Offering[], OfferingsState>
> = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERINGS:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFERINGS_SUCCESS:
      return {
        ...state,
        offerings: action.payload,
        loading: false,
      };
    case GET_OFFERINGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ADD_OFFERING:
    case PUBLISH_OFFERING:
    case UNPUBLISH_OFFERING:
    case ARCHIVE_OFFERING:
    case UNARCHIVE_OFFERING:
    case CLONE_OFFERING:
      return {
        ...state,
        error: null,
        submitting: true,
      };
    case ADD_OFFERING_SUCCESS:
    case PUBLISH_OFFERING_SUCCESS:
    case UNPUBLISH_OFFERING_SUCCESS:
    case ARCHIVE_OFFERING_SUCCESS:
    case UNARCHIVE_OFFERING_SUCCESS:
    case CLONE_OFFERING_SUCCESS:
      return {
        ...state,
        error: null,
        submitting: false,
      };
    case ADD_OFFERING_FAIL:
      return {
        ...state,
        error: action.error,
        submitting: false,
      };
    case PUBLISH_OFFERING_FAIL:
    case UNPUBLISH_OFFERING_FAIL:
    case ARCHIVE_OFFERING_FAIL:
    case UNARCHIVE_OFFERING_FAIL:
    case CLONE_OFFERING_FAIL:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

export default offeringsReducer;
